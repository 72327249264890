import Image from 'next/image'
import { useEffect, useState, useRef } from 'react'
import Link from 'next/link'
import { timeDiff } from './'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google'
import { newLive, SERVER_URL, validateEmail } from '../js'
import { useRouter } from 'next/router'
import Script from 'next/script'
//import { useTracking } from '../js/useTracking'

export async function fetchWithRetry(url, options, maxRetries = 5) {
  let attempts = 0
  let lastError

  while (attempts < maxRetries) {
    try {
      const response = await fetch(url, options)
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`)
      }
      // Check the content type of the response
      const contentType = response.headers.get('content-type')
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Received non-JSON response')
      }
      return await response.json()
    } catch (error) {
      lastError = error
      attempts++
      console.log(`Attempt ${attempts} failed: ${error.message}`)
      // Wait for a moment before retrying (optional)
      await new Promise((resolve) => setTimeout(resolve, 1000))
    }
  }

  // If all attempts fail, throw the last error
  throw lastError
}

const prettify = (value) => {
  switch (value) {
    case 'en':
      return 'English'
    case 'es':
      return 'Español'
    case 'pt':
      return 'Português'
    case 'fr':
      return 'Français'
    case 'it':
      return 'Italiano'
    case 'de':
      return 'Deutsch'
    default:
      return 'English'
  }
}

export const OurClients = ({ className, content }) => {
  return (
    <section className={`clients ${className}`}>
      <div className="title">{content ? content.title : 'They trust us'}</div>
      <div className="logos">
        <Image width={125} height={30} src="/images/Telefonica.png" alt="Telefonica" />
        <Image width={53} height={30} src="/images/Orange.png" alt="Orange" />
        <Image width={62} height={30} src="/images/F45.svg" alt="F45" />
        <Image width={120} height={30} src="/images/Huawei.png" alt="Huawei" />
        <Image width={149} height={30} src="/images/Manualto.svg" alt="Manualto" />
      </div>
    </section>
  )
}

export const ImageSvg = ({ logo, big }) => {
  return (
    <svg id="Layer_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1141.92 689.8">
      <defs></defs>
      <g id="Design">
        <path
          className="cls-19"
          d="M1059.53,238.19c-14.61-21.61-60.84-82.22-126.74-98.2-42.21-10.24-101.73-.39-121.88,1.77-12.73,1.36-47.49,2.59-76.32-8.25-22.11-8.32-30.84-19.93-53.79-40.52-19.29-17.31-48.71-41.04-90.11-65.17h0C523.03-8.32,452.44-.82,407.82,4.5c-37.99,4.52-93.02,11.08-154.35,44.56C116.7,123.74,73.13,264.37,69.76,275.87c-5.71,19.48-55.28,198.07,45.31,305.21,45.94,48.94,108.73,67.91,108.73,67.91,40.58,12.26,76.2,10.97,103,10,34.69-1.26,60.76-6.76,85-12,55.52-12,85.42-25.29,105-31,90.03-26.23,143.72,55.07,247,71,112.99,17.43,262.8-46.86,320-170,43.07-92.72,26.49-203.73-24.26-278.8Z"
        />
        <path
          className="cls-19"
          d="M204.94,41.97C106.25,115,46.7,221.52,29.12,342.24c-3.76,26.7-5.29,54.16-3.52,81.08,.48,10.31,1.88,21.88,3.38,32.12,1.65,10.1,3.83,21.64,6.57,31.49,8.37,32.74,23.32,64.25,44.2,90.89,10.09,12.84,21.6,24.52,34.11,35.1,11.23,9.47,23.37,18.03,36.08,26.05-28.53-9.37-55.48-25.2-77.9-46.56C-21.1,502.12-12.27,351.41,33.06,239.5,67.6,154.5,124.49,86.69,204.94,41.97h0Z"
        />
        <path
          className="cls-19"
          d="M1131.51,392.26c4.33,12.21,7.06,25.01,8.72,37.97,1.65,12.97,2.07,26.16,1.37,39.35-1.43,26.38-7.78,52.82-19.05,77.21-11.23,24.36-27.2,46.81-47.45,64.39-10.08,8.79-21.09,16.46-32.83,22.61-5.86,3.09-11.89,5.81-18.06,8.13-6.17,2.31-12.45,4.27-18.92,5.55,10.2-8.31,20.08-16.26,29.36-24.69,9.36-8.31,18.09-17.05,26.34-26.07,8.23-9.05,15.89-18.49,22.95-28.36l1.34-1.84,1.27-1.88,2.57-3.75c1.71-2.51,3.25-5.12,4.89-7.67l4.56-7.87,2.16-4.01c.73-1.33,1.45-2.67,2.1-4.04,10.98-21.68,18.67-45.15,23.22-69.56,4.64-24.43,6.19-49.66,5.45-75.48Z"
        />
        <path
          className="cls-10"
          d="M327.96,426.48c-32.2,.29-64.4,.57-96.6,.86-46.79-6.85-79.22-48.67-78.05-89.45,1.38-48.43,50.1-93.56,106.69-81.82,1.68-4.06,21.6-50.14,69.82-59.78,44.78-8.95,76.98,20.92,80.81,24.59,7.54-15.81,24.61-45.41,57.07-62.32,56.71-29.54,133.9-6.56,166.87,50,16.93,29.03,17.85,59.44,17.29,74.64,53.96-10.21,99.42,20.47,107.42,58.4,5.08,24.07-3.22,59.12-27.71,76.54-11.73,8.34-23.77,10.23-30.88,10.68,32.79-.21,65.58-.43,98.37-.64"
        />
        <polygon
          className="cls-12"
          points="815.96 427.94 788.41 406.81 788.41 449.07 815.96 427.94"
        />
        <circle className="cls-2" cx="323.26" cy="438.58" r="99.98" />
        {big ? (
          <image x="248.26" y="363.58" href={logo} height="150" width="150" />
        ) : (
          <image x="273.26" y="388.58" href={logo} height="100" width="100" />
        )}
        <circle className="cls-2" cx="925.54" cy="440.14" r="96.66" />
        <image x="875.54" y="390.14" href="/images/Logo.svg" height="100" width="100" />
        <g>
          <g className="cls-13">
            <circle cx="213.11" cy="458.2" r="49.52" />
            <path d="M198.57,482.51c-1.17,0-2.3-.63-2.88-1.73-.84-1.59-.24-3.57,1.35-4.41l34.29-18.16-35.82-19.01c-1.59-.84-2.2-2.82-1.35-4.41,.84-1.59,2.82-2.2,4.41-1.35l41.25,21.89c1.07,.57,1.73,1.67,1.73,2.88,0,1.21-.67,2.31-1.73,2.88l-39.73,21.04c-.49,.26-1.01,.38-1.52,.38Z" />
            <path d="M205.92,471.66l-8.87,4.7c-1.59,.84-2.2,2.82-1.35,4.41,.58,1.1,1.71,1.73,2.88,1.73,.52,0,1.04-.12,1.52-.38l8.87-4.7c-.39-2.24-1.48-4.24-3.05-5.76Z" />
            <path d="M239.82,455.32l-11.62-6.16c-1.45,1.62-2.52,3.58-3.06,5.76l6.18,3.28-6.19,3.28c.54,2.18,1.6,4.14,3.05,5.76l11.62-6.16c1.07-.56,1.73-1.67,1.73-2.88,0-1.21-.67-2.32-1.73-2.88Z" />
            <path d="M194.16,434.78c-.84,1.59-.24,3.56,1.35,4.41l7.92,4.2c1.63-1.47,2.74-3.48,3.06-5.76l-7.92-4.2c-1.59-.84-3.57-.24-4.41,1.35Z" />
            <path d="M203.96,436.31c0,3.82-3.1,6.92-6.92,6.92s-6.92-3.1-6.92-6.92,3.1-6.92,6.92-6.92,6.92,3.1,6.92,6.92Z" />
            <path d="M205.49,479.24c0,3.82-3.1,6.92-6.92,6.92s-6.92-3.1-6.92-6.92,3.1-6.92,6.92-6.92,6.92,3.1,6.92,6.92Z" />
            <path d="M247.69,458.2c0,5.19-4.2,9.39-9.39,9.39s-9.39-4.2-9.39-9.39,4.2-9.39,9.39-9.39,9.39,4.2,9.39,9.39Z" />
          </g>
          <g>
            <circle className="cls-17" cx="224.07" cy="454.29" r="49.52" />
            <path
              className="cls-7"
              d="M209.53,478.59c-1.17,0-2.3-.63-2.88-1.73-.84-1.59-.24-3.57,1.35-4.41l34.29-18.16-35.82-19.01c-1.59-.84-2.2-2.82-1.35-4.41,.84-1.59,2.82-2.2,4.41-1.35l41.25,21.89c1.07,.57,1.73,1.67,1.73,2.88,0,1.21-.67,2.31-1.73,2.88l-39.73,21.04c-.49,.26-1.01,.38-1.52,.38Z"
            />
            <path
              className="cls-15"
              d="M216.87,467.75l-8.87,4.7c-1.59,.84-2.2,2.82-1.35,4.41,.58,1.1,1.71,1.73,2.88,1.73,.52,0,1.04-.12,1.52-.38l8.87-4.7c-.39-2.24-1.48-4.24-3.05-5.76Z"
            />
            <path
              className="cls-15"
              d="M250.78,451.41l-11.62-6.16c-1.45,1.62-2.52,3.58-3.06,5.76l6.18,3.28-6.19,3.28c.54,2.18,1.6,4.14,3.05,5.76l11.62-6.16c1.07-.56,1.73-1.67,1.73-2.88,0-1.21-.67-2.32-1.73-2.88Z"
            />
            <path
              className="cls-15"
              d="M205.12,430.87c-.84,1.59-.24,3.56,1.35,4.41l7.92,4.2c1.63-1.47,2.74-3.48,3.06-5.76l-7.92-4.2c-1.59-.84-3.57-.24-4.41,1.35Z"
            />
            <path
              className="cls-7"
              d="M214.92,432.4c0,3.82-3.1,6.92-6.92,6.92s-6.92-3.1-6.92-6.92,3.1-6.92,6.92-6.92,6.92,3.1,6.92,6.92Z"
            />
            <path
              className="cls-7"
              d="M216.44,475.33c0,3.82-3.1,6.92-6.92,6.92s-6.92-3.1-6.92-6.92,3.1-6.92,6.92-6.92,6.92,3.1,6.92,6.92Z"
            />
            <path
              className="cls-7"
              d="M258.64,454.29c0,5.19-4.2,9.39-9.39,9.39s-9.39-4.2-9.39-9.39,4.2-9.39,9.39-9.39,9.39,4.2,9.39,9.39Z"
            />
          </g>
        </g>
        <g>
          <polygon
            className="cls-1"
            points="841.06 221.81 885.13 316.76 839.92 243.49 841.06 221.81"
          />
          <polygon
            className="cls-3"
            points="821.67 251.35 885.13 316.76 839.92 243.49 821.67 251.35"
          />
          <polygon
            className="cls-14"
            points="821.67 251.35 885.13 316.76 836.84 259.38 821.67 251.35"
          />
          <polygon
            className="cls-1"
            points="815.93 290.97 885.13 316.76 836.84 259.38 815.93 290.97"
          />
        </g>
        <circle className="cls-18" cx="924.95" cy="291.71" r="21.07" />
        <g>
          <path
            className="cls-18"
            d="M118.44,381.67h0c1.16,0,2.1-.94,2.1-2.1v-22.51c0-1.16-.94-2.1-2.1-2.1h0c-1.16,0-2.1,.94-2.1,2.1v22.51c0,1.16,.94,2.1,2.1,2.1Z"
          />
          <path
            className="cls-18"
            d="M131.8,368.31h0c0-1.16-.94-2.1-2.1-2.1h-22.51c-1.16,0-2.1,.94-2.1,2.1h0c0,1.16,.94,2.1,2.1,2.1h22.51c1.16,0,2.1-.94,2.1-2.1Z"
          />
        </g>
        <circle className="cls-9" cx="821.67" cy="511.79" r="13.14" />
        <circle className="cls-1" cx="133.9" cy="419.46" r="21.07" />
        <circle className="cls-5" cx="1049.44" cy="444.9" r="15.59" />
        <g>
          <path
            className="cls-12"
            d="M808.64,384.79h0c1.62,0,2.92-1.31,2.92-2.92v-31.31c0-1.62-1.31-2.92-2.92-2.92h0c-1.62,0-2.92,1.31-2.92,2.92v31.31c0,1.62,1.31,2.92,2.92,2.92Z"
          />
          <path
            className="cls-12"
            d="M827.22,366.21h0c0-1.62-1.31-2.92-2.92-2.92h-31.31c-1.62,0-2.92,1.31-2.92,2.92h0c0,1.62,1.31,2.92,2.92,2.92h31.31c1.62,0,2.92-1.31,2.92-2.92Z"
          />
        </g>
        <g className="cls-4">
          <path
            className="cls-8"
            d="M730.03,491.29c.69,0,1.38-.14,2.05-.43l24.38-10.64c1.89-.83,3.1-2.7,3.08-4.77-.03-2.06-1.29-3.91-3.2-4.69l-24.38-9.91c-2.62-1.07-5.62,.2-6.68,2.82-1.07,2.62,.2,5.62,2.82,6.68l13.1,5.32-13.22,5.77c-2.6,1.13-3.78,4.16-2.65,6.75,.84,1.93,2.73,3.08,4.7,3.08Z"
          />
          <path
            className="cls-8"
            d="M681.97,491.29c1.98,0,3.86-1.15,4.7-3.08,1.13-2.6-.05-5.62-2.65-6.75l-13.22-5.77,13.1-5.32c2.62-1.07,3.89-4.06,2.82-6.68-1.07-2.62-4.06-3.89-6.68-2.82l-24.38,9.91c-1.91,.78-3.17,2.62-3.2,4.69-.03,2.06,1.19,3.94,3.08,4.77l24.38,10.64c.67,.29,1.36,.43,2.05,.43Z"
          />
          <path
            className="cls-8"
            d="M709.06,497.3c.43,0,.88-.06,1.32-.17,2.74-.72,4.37-3.53,3.65-6.27l-8.91-33.67c-.72-2.74-3.53-4.37-6.27-3.65-2.74,.72-4.37,3.53-3.65,6.27l8.91,33.67c.61,2.3,2.68,3.82,4.96,3.82Z"
          />
        </g>
        <g className="cls-16">
          <circle
            cx="992.51"
            cy="501.83"
            r="40.94"
            transform="translate(-64.15 848.79) rotate(-45)"
          />
          <path d="M984.12,521.28h0c-1.05,0-2.06-.42-2.81-1.16l-10.03-10.03c-1.55-1.55-1.55-4.06,0-5.61,1.55-1.55,4.06-1.55,5.61,0l7.22,7.22,28.15-28.15c1.55-1.55,4.06-1.55,5.62,0,1.55,1.55,1.55,4.06,0,5.62l-30.96,30.96c-.74,.74-1.75,1.16-2.81,1.16Z" />
        </g>
        <g>
          <circle
            className="cls-6"
            cx="999.04"
            cy="497.31"
            r="39.7"
            transform="translate(-59.04 852.08) rotate(-45)"
          />
          <path
            className="cls-1"
            d="M990.9,516.17h0c-1.02,0-2-.41-2.72-1.13l-9.73-9.73c-1.5-1.5-1.5-3.94,0-5.45,1.5-1.5,3.94-1.5,5.45,0l7.01,7.01,27.3-27.3c1.5-1.5,3.94-1.5,5.45,0,1.5,1.5,1.5,3.94,0,5.45l-30.03,30.03c-.72,.72-1.7,1.13-2.72,1.13Z"
          />
        </g>
        <path
          className="cls-11"
          d="M219.25,366.53c8.82-16.79,32.95-57.12,82.1-82.1,30.62-15.56,57.58-18.38,65.98-19.06,87.98-7.14,148.69,56.96,170.38,88.54,11.24,16.35,13.12,25.59,10.18,32.22-7.26,16.32-45.83,21.17-58.14,6.06-9.19-11.27-1.36-30.75,6.6-49.85,8.41-20.18,28.08-58.83,75.59-97.33,22.18-17.98,51.81-41.99,98.15-52.94,22.19-5.24,54.63-12.91,90.9,0,55.47,19.74,78.63,72.89,83.57,85.04"
        />
      </g>
    </svg>
  )
}

export const FaqBlackFriday = () => {
  return (
    <section className="freques">
      <div className="title">Frequently Asked Questions</div>
      <div className="faq">
        <input id="faq-b" type="checkbox" />
        <label htmlFor="faq-b">
          <p className="faq-heading">Who is eligible for the Black Friday promotion?</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">
            You can take advantage of this promotion if you are new to Edworking or if you have
            a Free plan.
          </p>
        </label>
        <input id="faq-c" type="checkbox" />
        <label htmlFor="faq-c">
          <p className="faq-heading">How are discounts calculated?</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">
            The discount percentages are based on a standard yearly pricing for the service.
          </p>
        </label>
        <input id="faq-d" type="checkbox" />
        <label htmlFor="faq-d">
          <p className="faq-heading">
            How much will my Edworking Black Friday subscription cost?
          </p>
          <div className="faq-arrow"></div>
          <p className="faq-text">
            On Black Friday 2024 Edworking offers: 30% off the annual plans. We do not make a
            refund for this special offer.
          </p>
        </label>
        <input id="faq-e" type="checkbox" />
        <label htmlFor="faq-e">
          <p className="faq-heading">What payment methods do we accept?</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">You can use your credit card to pay for any plan.</p>
        </label>
      </div>
    </section>
  )
}

export const FaqReferral = ({ content }) => {
  return (
    <section
      className="freques"
      style={{
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F0F6F7)',
        margin: 0,
        paddingBottom: 40,
      }}
    >
      <div className="title">{content.title}</div>
      <div className="description">
        {content.description1}
        <a href="https://edworking.com/contact">{content.description2}</a>
        {content.description3}
      </div>
      <div className="faq">
        <input id="faq-a" type="checkbox" />
        <label htmlFor="faq-a">
          <p className="faq-heading">{content.list[0].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[0].answer}</p>
        </label>
        <input id="faq-b" type="checkbox" />
        <label htmlFor="faq-b">
          <p className="faq-heading">{content.list[1].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[1].answer}</p>
        </label>
        <input id="faq-c" type="checkbox" />
        <label htmlFor="faq-c">
          <p className="faq-heading">{content.list[2].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[2].answer}</p>
        </label>
        <input id="faq-d" type="checkbox" />
        <label htmlFor="faq-d">
          <p className="faq-heading">{content.list[3].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[3].answer}</p>
        </label>
        <input id="faq-e" type="checkbox" />
        <label htmlFor="faq-e">
          <p className="faq-heading">{content.list[4].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[4].answer}</p>
        </label>
        <input id="faq-f" type="checkbox" />
        <label htmlFor="faq-f">
          <p className="faq-heading">{content.list[4].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[4].answer}</p>
        </label>
      </div>
    </section>
  )
}
export const FaqAffiliate = ({ content }) => {
  return (
    <section
      className="freques"
      style={{
        margin: 0,
        paddingBottom: 40,
        background: 'linear-gradient(180deg, #FFFFFF 0%, #F0F6F7)',
      }}
    >
      <div className="title">{content.title}</div>
      <div className="description">
        {content.description1}
        <a href="https://edworking.com/contact">{content.description2}</a>
        {content.description3}
      </div>
      <div className="faq">
        <input id="faq-a" type="checkbox" />
        <label htmlFor="faq-a">
          <p className="faq-heading">{content.list[0].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[0].answer}</p>
        </label>
        <input id="faq-b" type="checkbox" />
        <label htmlFor="faq-b">
          <p className="faq-heading">{content.list[1].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[1].answer}</p>
        </label>
        <input id="faq-c" type="checkbox" />
        <label htmlFor="faq-c">
          <p className="faq-heading">{content.list[2].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[2].answer}</p>
        </label>
        <input id="faq-d" type="checkbox" />
        <label htmlFor="faq-d">
          <p className="faq-heading">{content.list[3].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[3].answer}</p>
        </label>
        <input id="faq-e" type="checkbox" />
        <label htmlFor="faq-e">
          <p className="faq-heading">{content.list[4].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[4].answer}</p>
        </label>
        <input id="faq-f" type="checkbox" />
        <label htmlFor="faq-f">
          <p className="faq-heading">{content.list[4].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[4].answer}</p>
        </label>
        <input id="faq-g" type="checkbox" />
        <label htmlFor="faq-g">
          <p className="faq-heading">{content.list[5].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[5].answer}</p>
        </label>
      </div>
    </section>
  )
}
export const FaqPricing = ({ content }) => {
  return (
    <section className="freques">
      <div className="title">{content.title}</div>
      <div className="description">
        {content.description1}
        <Link href="/contact">{content.description2}</Link>
        {content.description3}
      </div>
      <div className="faq">
        <input id="faq-a" type="checkbox" />
        <label htmlFor="faq-a">
          <p className="faq-heading">{content.list[0].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[0].answer}</p>
        </label>
        <input id="faq-b" type="checkbox" />
        <label htmlFor="faq-b">
          <p className="faq-heading">{content.list[1].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[1].answer}</p>
        </label>
        <input id="faq-c" type="checkbox" />
        <label htmlFor="faq-c">
          <p className="faq-heading">{content.list[2].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[2].answer}</p>
        </label>
        <input id="faq-d" type="checkbox" />
        <label htmlFor="faq-d">
          <p className="faq-heading">{content.list[3].question}</p>
          <div className="faq-arrow"></div>
          <p className="faq-text">{content.list[3].answer}</p>
        </label>
      </div>
    </section>
  )
}
export const EdworkingBanner = ({ content, disableButton }) => {
  return (
    <div className="featuresed">
      <h2 className="titlehead">
        {content.title1} <span style={{ color: '#16DAf4' }}>{content.title2}</span>{' '}
        {content.title3}
      </h2>
      {!disableButton && (
        <Link href="https://app.edworking.com/signup/user/" className="blueButton">
          {content.button}
        </Link>
      )}
      <Image
        height={1294}
        width={2376}
        className="logo"
        src="/images/Group.png"
        alt="Collage Edworking"
      />
    </div>
  )
}

export const TopBanner = ({ content }) => {
  const [close, setClose] = useState(false)
  return (
    <>
      {!close && (
        <section className="banner top" style={{ display: 'flex' }}>
          <div className="subheadline">
            Unlock exclusive deals on Edworking's yearly plans. Save 30% today!
          </div>
          <a className="whiteButton" href="https://edworking.com/black-friday">
            Go to Pricing
          </a>
          {/*<div className="close" onClick={() => setClose(true)} >
            <Image width={22} height={22} src="/images/ClosePromotion.png" alt="Close banner" />
          </div>*/}
        </section>
      )}
    </>
  )
}

export const Offer = ({ content }) => {
  const [open, setOpen] = useState(false)
  const [codes, setCodes] = useState()

  useEffect(() => {
    if (codes > 0) {
      const goon = localStorage.getItem('lifetime')
      if (!goon) setOpen(true)
    }
  }, [codes])

  useEffect(() => {
    fetch('https://europe-west2-edworking.cloudfunctions.net/lifetimecodesAvailable')
      .then((res) => res.json())
      .then((res) => {
        setCodes(res.lifetimes)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <>
      {open && (
        <section className="edpromotion offer">
          <div className="banner offer">
            <Image width="32" height="20" className="image" src="/images/Logo.svg" alt="" />
            <div className="text">
              <div className="headline">{content.header}</div>
              <div className="subheadline">{content.description}</div>
              <div className="subheadline">
                {content.only}
                <span> {codes} </span>
                {content.codes}
              </div>
              <div className="bottom">
                <a className="blueButton" href="https://edworking.com/pricing">
                  {content.cta}
                </a>
              </div>
            </div>
            <div
              className="close"
              onClick={() => {
                setOpen(false), localStorage.setItem('lifetime', true)
              }}
            >
              <Image
                width={22}
                height={22}
                src="/images/ClosePromotion.png"
                alt="Close popup"
              />
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export const Testimonials = ({ content }) => {
  return (
    <section className="reviews">
      <div className="projectManagement">
        <Image
          width={269}
          height={292}
          src="/images/main/Jorim.png"
          alt="Project Management"
        />
        <div className="title">Jorim Rademaker</div>
        <div className="company">Manual.to</div>
        <div className="description">{content[0].description}</div>
      </div>
      <div className="projectManagement">
        <Image
          width={269}
          height={292}
          src="/images/main/Salva.png"
          alt="Project Management"
        />
        <div className="title">Salva Jovells</div>
        <div className="company">Hockerty</div>
        <div className="description">{content[1].description}</div>
      </div>
      <div className="projectManagement">
        <Image
          width={269}
          height={292}
          src="/images/main/Noelia.png"
          alt="Project Management"
        />
        <div className="title">Noelia Alonso</div>
        <div className="company">Banco Santander</div>
        <div className="description">{content[2].description}</div>
      </div>
    </section>
  )
}
export const Oops = ({ content }) => {
  return (
    <div className="oops">
      <Image width={329} height={198} src="/images/main/Oops.png" alt="Logo Asana" />
      <span className="title">{content.title}</span>
      <span className="description">{content.description}</span>
      <Link href="/" className="blueButton">
        {content.button}
      </Link>
    </div>
  )
}
/*
export const Reviews = () => {
  return(
    <section className="reviews">
      <h2 className="title">What Our Client Says</h2>
      <h4 className="description">More than 1000 teams are using Edworking in their daily work. These are just some of their thoughts. </h4>
      <div className="projectManagement">
        <Image width={269} height={292} src="/images/Manual.png" alt="Project Management" />
        <div className="title">Jorim Rademaker</div>
        <div className="company">Manual.to</div>
        <div className="description">The interface is clean and easy to navigate. It&apos;s intuitive, and the functionality is clear. It&apos;s easy to add tasks and manage them. I can see at a glance what tasks are assigned to me and what&apos;s still pending. </div>
      </div>
      <div className="projectManagement">
        <Image width={269} height={292} src="/images/Salva.png" alt="Project Management" />
        <div className="title">Salva Jovells</div>
        <div className="company">Hockerty</div>
        <div className="description">The communication features are really great. You can create a group chat room, set up a private chat with someone, send a direct message, and even video conference with your team members. There is no need to subscribe to Zoom, Skype, or use Google Meet.</div>
      </div>
      <div className="projectManagement">
        <Image width={269} height={292} src="/images/Noelia.png" alt="Project Management" />
        <div className="title">Noelia Alonso</div>
        <div className="company">Banco Santander</div>
        <div className="description">It&apos;s a great platform that allows us to collaborate, communicate, and manage our projects easily. Best of all, it was free to try, so we could test whether it would work for us or not before we subscribed.</div>
      </div>
    </section>  
  )
}
*/
export const Migrate = ({ content, company }) => {
  const [logo, setLogo] = useState(0)
  const logos = ['Asana', 'Airtable', 'Trello', 'Notion', 'Monday', 'slack']
  const refLogo = useRef(null)
  refLogo.current = logo
  useEffect(() => {
    let timer1 = setInterval(() => setLogo((refLogo.current + 1) % 5), 2 * 1000)
    return () => clearTimeout(timer1)
  }, [])

  return (
    <section className="import stuff">
      <Image
        width={240}
        height={216}
        src="/images/main/Meet1.png"
        className="img"
        style={{ position: 'absolute', width: 240, left: '5%', top: -145 }}
        alt="Meeting"
      />
      <Image
        width={191}
        height={173}
        src="/images/main/Meet2.png"
        className="img"
        style={{ position: 'absolute', width: 191, right: '5%', top: -34 }}
        alt="Meeting"
      />
      <Image
        width={189}
        height={152}
        src="/images/main/Meet3.png"
        className="img"
        style={{ position: 'absolute', width: 189, left: '5%', bottom: -120 }}
        alt="Meeting"
      />
      <Image
        width={148}
        height={121}
        src="/images/main/Meet4.png"
        className="img"
        style={{ position: 'absolute', width: 148, right: '5%', bottom: -50 }}
        alt="Meeting"
      />
      <div className="column">
        <ImageSvg logo={company ? company : `/images/compare/${logos[logo]}.png`} />
      </div>
      <div className="column" style={{ width: 'fit-content' }}>
        <h2 className="title">{content.title}</h2>
        <h4 className="description">{content.description}</h4>
        <a
          className="blueButton"
          style={{ margin: 10, width: 300 }}
          href="https://edworking.com/contact"
        >
          {content.button}
        </a>
        <h2 style={{ color: '#fcb504', fontSize: 16 }}>{content.subbutton}</h2>
        <div className="imports">
          <a href="https://edworking.com/compare/edworking-vs-asana">
            <Image width={30} height={30} src="/images/compare/Asana.png" alt="Logo Asana" />
          </a>
          <a href="https://edworking.com/compare/edworking-vs-airtable">
            <Image
              width={30}
              height={30}
              src="/images/compare/Airtable.png"
              alt="Logo Airtable"
            />
          </a>
          <a href="https://edworking.com/compare/edworking-vs-trello">
            <Image width={30} height={30} src="/images/compare/Trello.png" alt="Logo Trello" />
          </a>
          <a href="https://edworking.com/compare/edworking-vs-notion">
            <Image width={30} height={30} src="/images/compare/Notion.png" alt="Logo Notion" />
          </a>
          <a href="https://edworking.com/compare/edworking-vs-monday">
            <Image width={30} height={30} src="/images/compare/Monday.png" alt="Logo Monday" />
          </a>
          <a href="https://edworking.com/compare/edworking-vs-slack">
            <Image width={30} height={30} src="/images/compare/Slack.png" alt="Logo Slack" />
          </a>
        </div>
      </div>
    </section>
  )
}
function elementInViewport(el) {
  var top = el.offsetTop
  var left = el.offsetLeft
  var width = el.offsetWidth
  var height = el.offsetHeight

  while (el.offsetParent) {
    el = el.offsetParent
    top += el.offsetTop
    left += el.offsetLeft
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    top + height <= window.pageYOffset + window.innerHeight &&
    left + width <= window.pageXOffset + window.innerWidth
  )
}

export const MainFeatures = ({ content }) => {
  const [feature, setFeature] = useState('task')
  const [hover, setHover] = useState(null)
  const featureHover = hover ? hover : feature
  const [bar, setBar] = useState(0)

  const refNavigationtask = useRef(),
    refNavigationstory = useRef(),
    refNavigationfile = useRef(),
    refNavigationdoc = useRef(),
    refNavigationchat = useRef(),
    refNavigationvideo = useRef()

  return (
    <section className="featuresbanner">
      {feature === 'task' ? (
        <h2 className="title">{content.task.title}</h2>
      ) : feature === 'story' ? (
        <h2 className="title">{content.story.title}</h2>
      ) : feature === 'file' ? (
        <h2 className="title">{content.file.title}</h2>
      ) : feature === 'doc' ? (
        <h2 className="title">{content.doc.title}</h2>
      ) : feature === 'chat' ? (
        <h2 className="title">{content.chat.title}</h2>
      ) : (
        <h2 className="title">{content.video.title}</h2>
      )}
      <nav className="features-navigation">
        <div
          ref={refNavigationtask}
          onMouseOver={() => setHover('task')}
          onMouseLeave={() => setHover(null)}
          onClick={() => setFeature('task')}
          style={{
            borderBottom: feature === 'task' ? '3px solid #A14DBA' : '3px solid transparent',
          }}
        >
          {content.task.name}
        </div>
        <div
          ref={refNavigationstory}
          onMouseOver={() => setHover('story')}
          onMouseLeave={() => setHover(null)}
          onClick={() => setFeature('story')}
          style={{
            borderBottom: feature === 'story' ? '3px solid #FCB504' : '3px solid transparent',
          }}
        >
          {content.story.name}
        </div>
        <div
          ref={refNavigationfile}
          onMouseOver={() => setHover('file')}
          onMouseLeave={() => setHover(null)}
          onClick={() => setFeature('file')}
          style={{
            borderBottom: feature === 'file' ? '3px solid #FD3F67' : '3px solid transparent',
          }}
        >
          {content.file.name}
        </div>
        <div
          ref={refNavigationdoc}
          onMouseOver={() => setHover('doc')}
          onMouseLeave={() => setHover(null)}
          onClick={() => setFeature('doc')}
          style={{
            borderBottom: feature === 'doc' ? '3px solid #0AC9EF' : '3px solid transparent',
          }}
        >
          {content.doc.name}
        </div>
        <div
          ref={refNavigationchat}
          onMouseOver={() => setHover('chat')}
          onMouseLeave={() => setHover(null)}
          onClick={() => setFeature('chat')}
          style={{
            borderBottom: feature === 'chat' ? '3px solid #05D677' : '3px solid transparent',
          }}
        >
          {content.chat.name}
        </div>
        <div
          ref={refNavigationvideo}
          onMouseOver={() => setHover('video')}
          onMouseLeave={() => setHover(null)}
          onClick={() => setFeature('video')}
          style={{
            borderBottom: feature === 'video' ? '3px solid #839499' : '3px solid transparent',
          }}
        >
          {content.video.name}
        </div>
        <span
          className="bar"
          style={{
            width: eval(`refNavigation${featureHover}`)?.current?.clientWidth,
            left: eval(`refNavigation${featureHover}`)?.current?.offsetLeft,
            background:
              featureHover === 'task'
                ? '#A14DBA'
                : featureHover === 'story'
                  ? '#FCB504'
                  : featureHover === 'doc'
                    ? '#0AC9EF'
                    : featureHover === 'file'
                      ? '#07d4a0'
                      : featureHover === 'chat'
                        ? '#05D677'
                        : '#839499',
          }}
        ></span>
      </nav>
      <div className="feature">
        {feature === 'task' ? (
          <div className="task">
            <div className="infofeature">
              <span className="title" style={{ color: '#A14DBA' }}>
                {content.task.slogan}
              </span>
              <li className="description">
                <span>{content.task.benefits[0].title}</span>
                <p>{content.task.benefits[0].description}</p>
              </li>
              <li className="description">
                <span>{content.task.benefits[1].title}</span>
                <p>{content.task.benefits[1].description}</p>
              </li>
              <li className="description">
                <span>{content.task.benefits[2].title}</span>
                <p>{content.task.benefits[2].description}</p>
              </li>
              <Link className="blueButton" href="https://edworking.com/features/task">
                {content.button}
              </Link>
              <div className="description replaces">
                {content.replaces}
                <Image
                  width={20}
                  height={20}
                  src="/images/compare/Asana.png"
                  alt="Logo Asana"
                />
                <Image
                  width={20}
                  height={20}
                  src="/images/compare/Airtable.png"
                  alt="Logo Airtable"
                />
                <Image
                  width={20}
                  height={20}
                  src="/images/compare/Trello.png"
                  alt="Logo Trello"
                />
                <Image
                  width={20}
                  height={20}
                  src="/images/compare/Monday.png"
                  alt="Logo Monday"
                />
              </div>
            </div>
            <video
              muted
              autoPlay
              loop
              playsInline
              className="image"
              src="/videos/Tasks.mp4"
              poster="/images/EdworkingPreview.png"
            />
          </div>
        ) : feature === 'story' ? (
          <div className="story">
            <div className="infofeature">
              <span className="title" style={{ color: '#FCB504' }}>
                {content.story.slogan}
              </span>
              <li className="description">
                <span>{content.story.benefits[0].title}</span>
                <p>{content.story.benefits[0].description}</p>
              </li>
              <li className="description">
                <span>{content.story.benefits[1].title}</span>
                <p>{content.story.benefits[1].description}</p>
              </li>
              <li className="description">
                <span>{content.story.benefits[2].title}</span>
                <p>{content.story.benefits[2].description}</p>
              </li>
              <Link className="blueButton" href="https://edworking.com/features/collaboration">
                {content.button}
              </Link>
              <div className="description replaces">
                {content.replaces}
                <Image width={20} height={20} src="/images/main/Loom.png" alt="Logo Asana" />
              </div>
            </div>
            <video
              autoPlay
              muted
              loop
              playsInline
              className="image"
              src="/videos/Stories.mp4"
              poster="/images/EdworkingPreview.png"
            />
          </div>
        ) : feature === 'file' ? (
          <div className="file">
            <div className="infofeature">
              <span style={{ color: '#FD3F67' }} className="title">
                {content.file.slogan}
              </span>
              <li className="description">
                <span>{content.file.benefits[0].title}</span>
                <p>{content.file.benefits[0].description}</p>
              </li>
              <li className="description">
                <span>{content.file.benefits[1].title}</span>
                <p>{content.file.benefits[1].description}</p>
              </li>
              <li className="description">
                <span>{content.file.benefits[2].title}</span>
                <p>{content.file.benefits[2].description}</p>
              </li>
              <Link className="blueButton" href="https://edworking.com/features/collaboration">
                {content.button}
              </Link>
              <div className="description replaces">
                {content.replaces}
                <Image width={20} height={20} src="/images/main/Drive.png" alt="Logo Drive" />
                <Image
                  width={20}
                  height={20}
                  src="/images/main/Dropbox.png"
                  alt="Logo Dropbox"
                />
              </div>
            </div>
            <video
              autoPlay
              muted
              loop
              playsInline
              className="image"
              src="/videos/Files.mp4"
              poster="/images/EdworkingPreview.png"
            />
          </div>
        ) : feature === 'doc' ? (
          <div className="doc">
            <div className="infofeature">
              <span className="title" style={{ color: '#0AC9EF' }}>
                {content.doc.slogan}
              </span>
              <li className="description">
                <span>{content.doc.benefits[0].title}</span>
                <p>{content.doc.benefits[0].description}</p>
              </li>
              <li className="description">
                <span>{content.doc.benefits[1].title}</span>
                <p>{content.doc.benefits[1].description}</p>
              </li>
              <li className="description">
                <span>{content.doc.benefits[2].title}</span>
                <p>{content.doc.benefits[2].description}</p>
              </li>
              <Link className="blueButton" href="https://edworking.com/features/docs">
                {content.button}
              </Link>
              <div className="description replaces">
                {content.replaces}
                <Image width={20} height={20} src="/images/main/Notion.png" alt="Logo Asana" />
                <Image
                  width={20}
                  height={20}
                  src="/images/main/Confluence.png"
                  alt="Logo Airtable"
                />
              </div>
            </div>
            <video
              autoPlay
              muted
              loop
              playsInline
              className="image"
              src="/videos/Docs.mp4"
              poster="/images/EdworkingPreview.png"
            />
          </div>
        ) : feature === 'chat' ? (
          <div className="chat">
            <div className="infofeature">
              <span className="title" style={{ color: '#05D677' }}>
                {content.chat.slogan}
              </span>
              <li className="description">
                <span>{content.chat.benefits[0].title}</span>
                <p>{content.chat.benefits[0].description}</p>
              </li>
              <li className="description">
                <span>{content.chat.benefits[1].title}</span>
                <p>{content.chat.benefits[1].description}</p>
              </li>
              <li className="description">
                <span>{content.chat.benefits[2].title}</span>
                <p>{content.chat.benefits[2].description}</p>
              </li>
              <Link className="blueButton" href="https://edworking.com/features/chat">
                {content.button}
              </Link>
              <div className="description replaces">
                {content.replaces}
                <Image
                  width={20}
                  height={20}
                  src="/images/main/Whatsapp.png"
                  alt="Logo Asana"
                />
                <Image
                  width={20}
                  height={20}
                  src="/images/main/Slack.png"
                  alt="Logo Airtable"
                />
                <Image width={20} height={20} src="/images/main/Teams.png" alt="Logo Trello" />
              </div>
            </div>
            <video
              autoPlay
              muted
              loop
              playsInline
              className="image"
              src="/videos/Chat.mp4"
              poster="/images/EdworkingPreview.png"
            />
          </div>
        ) : (
          feature === 'video' && (
            <div className="video">
              <div className="infofeature">
                <span className="title" style={{ color: '#839499' }}>
                  {content.video.slogan}
                </span>
                <li className="description">
                  <span>{content.video.benefits[0].title}</span>
                  <p>{content.video.benefits[0].description}</p>
                </li>
                <li className="description">
                  <span>{content.video.benefits[1].title}</span>
                  <p>{content.video.benefits[1].description}</p>
                </li>
                <li className="description">
                  <span>{content.video.benefits[2].title}</span>
                  <p>{content.video.benefits[2].description}</p>
                </li>
                <Link className="blueButton" href="https://edworking.com/features/videocalls">
                  {content.button}
                </Link>
                <div className="description replaces">
                  {content.replaces}
                  <Image width={20} height={20} src="/images/main/Zoom.png" alt="Logo Asana" />
                  <Image
                    width={20}
                    height={20}
                    src="/images/main/Meets.png"
                    alt="Logo Airtable"
                  />
                  <Image
                    width={20}
                    height={20}
                    src="/images/main/Skype.png"
                    alt="Logo Trello"
                  />
                </div>
              </div>
              <video
                autoPlay
                muted
                loop
                playsInline
                className="image"
                src="/videos/Videocall.mp4"
                poster="/images/EdworkingPreview.png"
              />
            </div>
          )
        )}
      </div>
    </section>
  )
}
export const Features = ({ content }) => {
  return (
    <>
      <div className="allincluded">{content.title}</div>
      <section className="features">
        <div className="feature">
          <Image width={50} height={50} src="/images/Videocalls.svg" alt="Icon Videocalls" />
          <span className="title">{content.list[0].title}</span>
          <span className="description">{content.list[0].description}</span>
        </div>
        <div className="feature">
          <Image width={50} height={50} src="/images/Tasks.svg" alt="Icon Tasks" />
          <span className="title">{content.list[1].title}</span>
          <span className="description">{content.list[1].description}</span>
        </div>
        <div className="feature">
          <Image width={50} height={50} src="/images/Folder.svg" alt="Icon Files" />
          <span className="title">{content.list[2].title}</span>
          <span className="description">{content.list[2].description}</span>
        </div>
        <div className="feature">
          <Image width={50} height={50} src="/images/Workspace.svg" alt="Icon Documents" />
          <span className="title">{content.list[3].title}</span>
          <span className="description">{content.list[3].description}</span>
        </div>
        <div className="feature">
          <Image width={50} height={50} src="/images/Chat.svg" alt="Icon Chat" />
          <span className="title">{content.list[4].title}</span>
          <span className="description">{content.list[4].description}</span>
        </div>
        <div className="feature">
          <Image width={50} height={50} src="/images/Mobile.svg" alt="Icon Mobile" />
          <span className="title">{content.list[5].title}</span>
          <span className="description">{content.list[5].description}</span>
        </div>
      </section>
    </>
  )
}
export const TryEdworking = ({ content }) => {
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSearch(window?.location.search)
  }, [])

  return (
    <section className="tryedworking">
      <Image
        width={750}
        height={222}
        style={{ height: 'auto' }}
        src="/images/BackgroundTryit.png"
        alt="Try Edworking"
      />
      <span>
        {content.title1}&nbsp;
        <span style={{ color: '#0FC8EF' }}>{content.title2}</span>
        {content.title3}
      </span>
      <a
        style={{ zIndex: 5 }}
        href={`https://app.edworking.com/signup/user${search}`}
        rel="noopener"
      >
        <div className="blueButton">{content.button}</div>
      </a>
    </section>
  )
}
export const FeaturesIntro = ({ content }) => {
  return (
    <section className="feature" style={{ padding: '20px 10vw' }}>
      <h2 className="supratitle">{content.supratitle}</h2>
      <h2 className="title">{content.title}</h2>
      <h2 className="description">{content.description}</h2>
      <Link className="learnmore center" href="https://app.edworking.com/signup/user">
        {content.button}
        <Image
          width={15}
          height={18}
          className="image"
          src="/images/right-arrows.png"
          alt=""
        />
      </Link>
    </section>
  )
}

export const EdworkingPromotion = ({ promotion, content }) => {
  const [email, setEmail] = useState('')
  const [open, setOpen] = useState(false)
  const refBox = useRef()
  const refOpen = useRef()
  const refEmail = useRef()
  refOpen.current = open
  refEmail.current = email

  useEffect(() => {
    if (promotion) setOpen(true)
  }, [promotion])

  const closeIt = (e) => {
    if (refBox.current && !refBox?.current?.contains(e.target)) {
      localStorage.setItem('promotion', true)
      setOpen(false)
    }
  }

  const keyIt = (e) => {
    if (e.keyCode === 13 && refOpen.current) submit()
  }

  useEffect(() => {
    document.addEventListener('mousedown', closeIt)
    document.addEventListener('keydown', keyIt)

    return () => {
      document.removeEventListener('mousedown', closeIt)
      document.removeEventListener('keydown', keyIt)
    }
  }, [])

  const submit = () => {
    if (!validateEmail(refEmail.current))
      newLive('/images/Logo.svg', refEmail.current, ' is an invalid email')
    else {
      fetch(SERVER_URL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          operationName: 'NewUserPromotion',
          query:
            'mutation NewUserPromotion($email: String!, $promotion: Int) {newUserPromotion(email: $email, promotion: $promotion){ id }}',
          variables: { email: refEmail.current, promotion: 3 },
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          if (res.errors) {
            newLive('/images/Logo.svg', refEmail.current, ' is already registered')
          } else {
            window.location = `https://app.edworking.com/signup/user/u/${res.data.newUserPromotion.id}`
          }
          setEmail('')
        })
        .catch((e) => newLive('/images/Logo.svg', refEmail.current, ' is already registered'))
    }
  }

  if (!refOpen.current || !content) return null
  return (
    <section className="edpromotion">
      <div
        ref={refBox}
        className="newsletter container"
        style={{ backgroundImage: 'url(/images/PatternPromotion.png)' }}
      >
        <div
          className="close"
          onClick={() => {
            setOpen(false)
            localStorage.setItem('promotion', true)
          }}
        >
          <Image width={22} height={22} src="/images/ClosePromotion.png" alt="Close popup" />
        </div>
        <div className="text">
          <div className="newsTitle">{content?.title}</div>
          <div className="description">{content?.description} </div>
        </div>
        <div className="image">
          <Image
            width={241}
            height={197}
            style={{ height: 'auto' }}
            src="/images/LetterPromotion.png"
            alt=""
          />
        </div>
        <div className="emailinput">
          <input
            type="text"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="textInput"
            placeholder={content?.placeholder}
          />
          <input
            id="newsbutton"
            type="button"
            className="blueButton"
            onClick={() => submit()}
            value={content?.button}
          />
        </div>
      </div>
    </section>
  )
}
const Login = ({ setLogin, refLogin }) => {
  const [loading, setLoading] = useState(false)
  //const { trackEvent } = useTracking()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState({ email: false, password: false })
  const refBox = useRef(null)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  emailRef.current = email
  passwordRef.current = password

  const closeIt = (e) => {
    if (refBox.current && !refBox?.current?.contains(e.target)) setLogin(false)
  }
  const logApple = (event) => loginApple(event.detail)

  useEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'
    document.body.appendChild(script)

    const script1 = document.createElement('script')
    script1.type = 'text/javascript'
    script1.src = 'https://accounts.google.com/gsi/client'
    document.body.appendChild(script1)

    document.onkeydown = (evt) => {
      evt = evt || window.event
      if (evt.keyCode === 13) login()
    }

    script.addEventListener('load', () => {
      window.AppleID.auth.init({
        clientId: 'com.edworking.signin3', // This is the service ID we created.
        scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
        redirectURI: 'https://edworking.com', // As registered along with our service ID
        usePopup: true, // Important if we want to capture the data apple sends on the client side.
      })
    })
    script1.addEventListener('load', () => {
      window.google.accounts.id.initialize({
        client_id: '116827135235-ugp1juviji4hb6t74irhsqbcokmejnc7.apps.googleusercontent.com',
        callback: handleCredentialResponse,
      })
      window.google.accounts.id.renderButton(
        document.getElementById('buttonDiv'),
        {
          type: 'standard',
          theme: 'outline',
          size: 'large',
          shape: 'pill',
          width: 150,
          height: 50,
          text: 'signin',
        } // customization attributes
      )
      window.google.accounts.id.prompt() // also display the One Tap dialog
    })
    // setLogin
    document.addEventListener('mousedown', closeIt)
    document.addEventListener('AppleIDSignInOnSuccess', logApple)
    return () => {
      document.removeEventListener('mousedown', closeIt)
      document.removeEventListener('AppleIDSignInOnSuccess', logApple)
    }
  }, [])

  function handleCredentialResponse(response) {
    loginGoogle(response.credential)
  }

  const login = async () => {
    if (emailRef.current === '' || passwordRef.current === '') {
      setError({
        email: emailRef.current === '',
        password: passwordRef.current === '',
      })
      emailRef.current === ''
        ? newLive('/images/Logo.svg', '', 'Enter a valid email')
        : passwordRef.current === '' &&
          newLive('/images/Logo.svg', '', 'Enter a valid password')
      return
    } else {
      setError({ email: false, password: false })
    }
    if (!validateEmail(emailRef.current)) {
      newLive('/images/Logo.svg', emailRef.current, ' is an invalid email')
      return
    }
    fetch(SERVER_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        operationName: 'Login',
        query:
          'mutation Login($email: String!, $password: String!, $project: ID) {login(email: $email, password: $password, project: $project){ token user { id firstname lastname email team { url plan } } __typename } }',
        variables: { email: emailRef.current, password: passwordRef.current },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.errors) {
          if (res.errors[0].message.includes('user')) {
            newLive('/images/Logo.svg', emailRef.current, ' is an invalid Email')
          } else if (res.errors[0].message.includes('Password')) {
            newLive('/images/Logo.svg', 'Password', ' is invalid. Try again.')
          } else newLive('/images/Logo.svg', 'Error: ', res.errors[0].message)
        } else if (res.data?.login) {
          const { user } = res.data?.login
          const subdomain = user.team?.plan !== 'business' ? 'app' : user?.team?.url ?? 'app'
          document.cookie = `islogged=${subdomain}.edworking.com;path=/;domain=edworking.com`
          window.location = `https://${subdomain}.edworking.com/?token=${res.data.login.token}`
        }
      })
  }

  const loginGoogle = async (googleUser) => {
    if (loading) return
    else setLoading(true)
    var id_token = googleUser
    fetch('https://venusaur.edworking.com/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        operationName: 'LoginGoogle',
        query:
          'mutation LoginGoogle($token: String!) {loginGoogle(token: $token) { isNew token user { id firstname lastname email team { url plan } cproject { id }}} }',
        variables: { token: id_token },
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false)
        if (res.data?.loginGoogle) {
          const { user } = res.data?.loginGoogle
          const subdomain = user.team?.plan !== 'business' ? 'app' : user?.team?.url ?? 'app'
          document.cookie = `islogged=${subdomain}.edworking.com;path=/;domain=edworking.com`
          window.location = `https://${subdomain}.edworking.com/?token=${res.data.loginGoogle.token}`
        }
      })
  }

  const loginApple = async (appleUser) => {
    if (loading) return
    else setLoading(true)
    if (appleUser) {
      fetch('https://venusaur.edworking.com/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          operationName: 'LoginApple',
          query:
            'mutation LoginApple($token: String!) {loginApple(token: $token ) { isNew token user { id firstname lastname email team { plan url } cproject { id }}} }',
          variables: { token: appleUser.authorization.id_token },
        }),
      })
        .then((res) => res.json())
        .then((res) => {
          setLoading(false)
          if (res.data?.loginApple) {
            const { user } = res.data?.loginApple
            const subdomain = user.team?.plan !== 'business' ? 'app' : user?.team?.url ?? 'app'
            document.cookie = `islogged=${subdomain}.edworking.com;path=/;domain=edworking.com`
            window.location = `https://${subdomain}.edworking.com/?token=${res.data.loginApple.token}` //_saveUserData().then(e => )
          }
        })
    }
  }

  return (
    <div id="signin" ref={refBox} className="signinBox">
      <Image
        width={36}
        height={30}
        src="/images/Logo.svg"
        className="logo"
        alt="Company Logo"
      />
      <div className="sublogo">Sign in to your account</div>
      <input
        type="text"
        style={{
          border: error.email
            ? '1px solid rgb(255, 148, 171)'
            : '1px solid rgba(223, 228, 229, 0.658824)',
        }}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="textInput"
        placeholder="Enter your email"
      />
      <input
        type="password"
        style={{
          border: error.password
            ? '1px solid rgb(255, 148, 171)'
            : '1px solid rgba(223, 228, 229, 0.658824)',
        }}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="textInput"
        placeholder="Enter your password"
      />
      <script src="https://accounts.google.com/gsi/client" async defer></script>
      <div className="blueButton" onClick={() => login()}>
        Enter Edworking
      </div>
      <div className="doublesign">
        <div id="buttonDiv"></div>
        <div
          id="appleid-signin"
          className="appleButton"
          data-width="150"
          data-height="40"
          data-color="black"
          data-border="true"
          data-border-radius="40"
        ></div>
      </div>
      <div className="options">
        <div
          onClick={() => (window.location = 'https://app.edworking.com/signup/forgot')}
          className="problems"
        >
          Can&apos;t sign in?
        </div>
        <div
          onClick={() => (window.location = 'https://app.edworking.com/signup/user/')}
          className="signup"
        >
          Sign up for an account
        </div>
      </div>
    </div>
  )
}

export const TaskFeatures = ({ content }) => {
  const colors = ['red', 'blue', 'yellow']
  return (
    <section id="features">
      {content.highlights.map((highlight, index) => (
        <div key={index} className="plane-wrapper">
          <div className="plane-inner">
            <div className={`plane ${colors[index % 3]}`}>
              <Image
                width={460}
                height={490}
                className="img"
                style={{ borderRadius: 15, height: 'auto', width: 'auto' }}
                src={highlight.img}
                alt="Tasks"
              />
            </div>
          </div>
          <div className="plane-title">
            <h2 className="supratitle">{highlight.supratitle}</h2>
            <h2 className="title" style={{ maxWidth: 420 }}>
              {highlight.title}
            </h2>
            <h4 className="description">{highlight.description}</h4>
          </div>
        </div>
      ))}
    </section>
  )
}

export const IntroCompare = ({ company, content }) => {
  return (
    <section className="import intro">
      <div className="column" style={{ width: '40%', zIndex: 2 }}>
        <h1 className="title">{company.title}</h1>
        <h2 className="description">{company.smallcompare}</h2>
        <a
          className="blueButton"
          href="https://app.edworking.com/signup/user/"
          style={{ width: 220 }}
        >
          {content.button}
        </a>
        <ReviewsTrust />
      </div>
      <div className="column" style={{ width: '60%' }}>
        <video
          preload="metadata"
          muted
          loop
          autoPlay
          className="video"
          src="https://storage.googleapis.com/dratini/VideoRecording/UIAnimationC.mp4"
          alt="Task Management"
        />
      </div>
    </section>
  )
}
export const SolutionCompare = ({ content }) => {
  return (
    <section className="import intro">
      <div className="column" style={{ width: '40%', zIndex: 2 }}>
        <h1 className="title">{content.intro.title}</h1>
        <h2 className="description">{content.intro.description}</h2>
        <a
          className="blueButton"
          href="https://app.edworking.com/signup/user/"
          style={{ width: 220 }}
        >
          {content.intro.button}
        </a>
        <ReviewsTrust />
      </div>
      <div className="column" style={{ width: '60%' }}>
        <Image
          width={870}
          height={830}
          className="video"
          src="/images/main/Allin1.png"
          alt="Task Management"
        />
      </div>
    </section>
  )
}

export const sendDataLayer = (eventAction, eventLabel) => {
  window.dataLayer.push({
    event: 'CTA',
    eventCategory: 'CTA',
    eventAction: eventAction,
    eventLabel: eventLabel,
  })
}
export function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    // only execute all the code below in client side
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Call handler right away so state gets updated with initial window size
    handleResize()

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export const Subheader = ({ type, title, author, created, length }) => {
  return (
    <div className={`subheader ${type}`} style={{ marginTop: 160 }}>
      <h1 className="title">{title}</h1>
      <div className="subtitle">
        <span className="author">
          BY <span>{author}</span>
        </span>
        <span className="created">{created}</span>
        <span className="length">{length} MINS READ</span>
      </div>
    </div>
  )
}
export const SubheaderWorkspace = ({ type, title, author, created, length }) => {
  return (
    <div className={`subheader ${type}`} style={{ marginTop: 120 }}>
      <h1 className="title" style={{ fontSize: 36 }}>
        {title}
      </h1>
    </div>
  )
}

export const HubspotChat = () => {
  useEffect(() => {
    // Create script component.
    let script = document.createElement('script')
    script.src = `//js-eu1.hs-scripts.com/25992597.js`
    script.async = true

    document.body.appendChild(script)
  }, [])
}
export const Plans = ({ content, locale, pricing, landing }) => {
  const [plan, setPlan] = useState('yearly')
  const [search, setSearch] = useState('')
  const [codes, setCodes] = useState()

  useEffect(() => {
    setSearch(window?.location.search)
  }, [])

  useEffect(() => {
    fetch('https://europe-west2-edworking.cloudfunctions.net/lifetimecodesAvailable')
      .then((res) => res.json())
      .then((res) => {
        setCodes(res.lifetimes)
      })
      .catch((e) => console.log(e))
  }, [])

  return (
    <section className="pricingintro">
      {landing ? (
        <h2 className="title">{content.title}</h2>
      ) : (
        <h1 className="title">{content.title}</h1>
      )}
      <h4 className="description" style={{ marginBottom: 38, width: 600 }}>
        {content.description}
      </h4>
      <div className="selector">
        <span id="selector" className={`top ${plan}`}></span>
        <div id="monthly" className="tab selected" onClick={() => setPlan('monthly')}>
          <span>{content.monthly}</span>
        </div>
        <div id="yearly" className="tab" onClick={() => setPlan('yearly')}>
          <span>{content.yearly}</span>
        </div>
        {/*<div id="lifetime" className="tab" onClick={() => setPlan('lifetime')}>
          <span>{content.lifetime}</span>
          <p className="save" style={{ fontSize: 11 }}>
            {content.limited}
          </p>
        </div>*/}
      </div>
      <div className="price">
        <div className="plan">
          <span className="title">
            <img src="/images/UnlockedBlack.svg" alt="Profile" />
            {content.free?.title}
          </span>
          <div className="value">
            <span className="currency" style={{ color: '#09323a' }}>
              £
            </span>
            <span className="num" style={{ color: '#09323a' }}>
              0
            </span>
          </div>
          <span className="line"></span>
          <div className="type">
            <Image width={20} height={20} src="/images/Clock.svg" alt="" />
            <span className="text">{content.free?.description}</span>
          </div>
          <a className="whiteButton" href={`https://app.edworking.com/signup/user${search}`}>
            {content.free?.button}
          </a>
          {content.free?.benefits.map((b, i) => (
            <div
              key={i}
              className={`list${i === content.free.benefits.length - 1 ? ' last' : ''}`}
            >
              {b !== '' && <Image width={20} height={20} src="/images/Correct.svg" alt="" />}
              <span className="text">{b}</span>
            </div>
          ))}
        </div>
        <div className="plan popular">
          {/* <div className="discount" id="discount">Save 50%</div>*/}
          <div className="popular">{content.popular}</div>
          <span className="title">
            <img src="/images/SeedlingBlack.svg" alt="Profile" />
            {content.starter?.title}
          </span>
          <div className="value">
            <span className="currency">£</span>
            <span className="num" id="pounds">
              {plan === 'monthly' ? 2 : plan === 'yearly' ? 1.5 : 50}
            </span>
            {/*<div className="sales">
							<span className="currency">£</span>
							<span className="num" id="pounds2">4</span>
						</div>*/}
          </div>
          <span className="line"></span>
          <div className="type">
            <Image width={20} height={20} src="/images/Clock.svg" alt="" />
            <span className="text">
              {plan === 'monthly'
                ? content.month
                : plan === 'yearly'
                  ? content.month
                  : content.forever}
            </span>
          </div>
          <a className="blueButton" href={`https://app.edworking.com/signup/user${search}`}>
            {content.starter?.button}
          </a>
          {content.starter?.benefits.map((b, i) => (
            <div
              key={i}
              className={`list${i === content.starter.benefits.length - 1 ? ' last' : ''}`}
            >
              {b !== '' && <Image width={20} height={20} src="/images/Correct.svg" alt="" />}
              <span className="text">{b}</span>
            </div>
          ))}
        </div>
        <div className="plan">
          <span className="title">
            <img src="/images/InfinityBlack.svg" alt="Profile" />
            {content.unlimited?.title}
          </span>
          <div className="value">
            <span className="currency">£</span>
            <span className="num" id="pounds">
              {plan === 'monthly' ? 4 : plan === 'yearly' ? 3 : 80}
            </span>
          </div>
          <span className="line"></span>
          <div className="type">
            <Image width={20} height={20} src="/images/Clock.svg" alt="" />
            <span className="text">
              {plan === 'monthly'
                ? content.month
                : plan === 'yearly'
                  ? content.month
                  : content.forever}
            </span>
          </div>
          <a className="blueButton" href={`https://app.edworking.com/signup/user${search}`}>
            {content.unlimited?.button}
          </a>
          {content.unlimited?.benefits.map((b, i) => (
            <div
              key={i}
              className={`list${i === content.unlimited.benefits.length - 1 ? ' last' : ''}`}
            >
              {b !== '' && <Image width={20} height={20} src="/images/Correct.svg" alt="" />}
              <span className="text">{b}</span>
            </div>
          ))}
        </div>
        <div className="plan">
          <span className="title">
            <img src="/images/BriefcaseBlack.svg" alt="Profile" />
            {content.business?.title}
          </span>
          <div className="value">
            <span className="currency">£</span>
            <span className="num" id="pounds">
              {plan === 'monthly' ? 8 : plan === 'yearly' ? 6 : 160}
            </span>
          </div>
          {/*<div className="value" style={{ marginBottom: 15 }}>
						<Image width={66} height={66} style={{height: 66}} src="/images/enterprise.png" alt="" />
					</div>*/}
          <span className="line"></span>
          <div className="type">
            <Image width={20} height={20} src="/images/Clock.svg" alt="" />
            <span className="text">
              {plan === 'monthly'
                ? content.month
                : plan === 'yearly'
                  ? content.month
                  : content.forever}
            </span>
          </div>
          <a className="blueButton" href={`https://app.edworking.com/signup/user${search}`}>
            {content.business?.button}
          </a>
          {content.business?.benefits.map((b, i) => (
            <div
              key={i}
              className={`list${i === content.business.benefits.length - 1 ? ' last' : ''}`}
            >
              {b !== '' && <Image width={20} height={20} src="/images/Correct.svg" alt="" />}
              <span className="text">{b}</span>
            </div>
          ))}
        </div>
      </div>
      ¨{' '}
      {pricing && (
        <div className="contact">
          <span className="description">{content.contact}</span>
          <a className="blueButton" href={`https://edworking.com/${locale}/contact`}>
            {content.cta}
          </a>
        </div>
      )}
    </section>
  )
}
export const sendPageview = (path) => {
  window.dataLayer?.push({
    event: 'virtualPageview',
    //'pageTitle': '',
    virtualPath: path,
  })
}
export const Pagination = () => {
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(1)
  const router = useRouter()
  const getLength = async () => {
    const res = await fetch(process.env.NEXT_PUBLIC_VENUSAUR, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        operationName: 'GetBlogsLength',
        query:
          'query GetBlogsLength($category: String, $language: String) {getBlogsLength(category: $category, language: $language)}',
        variables: {
          language: router.locale,
          category: router.query.category ? router.query.category : 'guides',
        },
      }),
    })
    const data = await res.json()
    if (page > data.data.getBlogsLength)
      router.push(`/blog/${router.query.category}`, undefined, {
        scroll: true,
      })
    setTotal(data.data.getBlogsLength)
  }
  useEffect(() => {
    if (router.query && router.query.page) setPage(parseInt(router.query.page))
    getLength()
  }, [router.locale])

  return (
    <div className="pagination">
      <span onClick={() => newPage(page - 1)}>{'<'}</span>
      {Array(Math.ceil(total / 8))
        .fill(null)
        .map((a, index) => (
          <Link
            href={
              router.query.category
                ? `/blog/${router.query.category}/page/${index + 1}`
                : `/guides/page/${index + 1}`
            }
            key={index}
            onClick={() => setPage(index + 1)}
            style={{ color: page === index + 1 ? '#02bcea' : '#09323A' }}
          >
            {index + 1}
          </Link>
        ))}
      <span onClick={() => page < Math.ceil(total / 8) && newPage(page + 1)}>{'>'}</span>
    </div>
  )
}

export const ReviewsTrust = () => {
  return (
    <div className="reviewslanding">
      <div className="stars">
        {Array(5)
          .fill(null)
          .map((a, index) => (
            <Image
              width={16}
              height={16}
              key={index}
              src={`/images/reviews/Star2.svg`}
              alt="user"
            />
          ))}{' '}
        <span style={{ color: 'rgb(9, 50, 58)', fontSize: 13, fontWeight: 'bold' }}>5/5</span>
        <span>Based on Reviews on:</span>
      </div>
      <div className="text">
        <Image
          priority
          width={30}
          height={30}
          src="/images/reviews/g2.png"
          onClick={() => window.open('https://www.g2.com/products/edworking_/reviews')}
          alt="Get App Reviews"
        />
        <Image
          priority
          width={77}
          height={16}
          src="/images/reviews/getapp.svg"
          onClick={() =>
            window.open('https://www.getapp.co.uk/software/2059267/edworking#reviews')
          }
          alt="Get App Reviews"
        />
        <Image
          priority
          width={128}
          height={99}
          src="/images/reviews/softwareadvice2.png"
          onClick={() =>
            window.open('https://www.softwareadvice.com/digital-workplace/edworking-profile/')
          }
          alt="Software Advice Reviews"
        />
        <Image
          priority
          width={128}
          height={30}
          src="/images/reviews/capterra2.png"
          onClick={() => window.open('https://www.capterra.co.uk/software/1012392/edworking')}
          alt="Software Advice Reviews"
        />
        <Image
          priority
          width={128}
          height={99}
          src="/images/reviews/appsumo.png"
          onClick={() => window.open('https://appsumo.com/products/edworking/#reviews')}
          alt="Software Advice Reviews"
        />
      </div>
    </div>
  )
}
const typingTool = {
  en: 'paragraph-typing-test',
  it: 'test-di-battitura',
  es: 'prueba-de-escritura',
  fr: 'test-de-dactylographie',
  de: 'tipp-test',
  pt: 'teste-de-digitação',
}
const managementTest = {
  en: 'management-aptitude-test',
  it: 'test-attitudine-gestione',
  es: 'prueba-aptitud-gestion',
  fr: 'test-aptitude-gestion',
  de: 'eignungstest-management',
  pt: 'teste-aptidao-gestao',
}
const communicationQuiz = {
  en: 'communication-style-quiz',
  it: 'quiz-stile-comunicazione',
  es: 'cuestionario-estilo-comunicacion',
  fr: 'quiz-style-communication',
  de: 'kommunikationsstil-quiz',
  pt: 'questionario-estilo-comunicaçao',
}
const hookGenerator = {
  en: 'writing-hook-generator',
  it: 'generatore-introduzioni',
  es: 'generador-ganchos',
  fr: 'generateur-crochet',
  de: 'haken-generator',
  pt: 'gerador-gancho',
}

const youtubeChannelDescriptionGenerator = {
  en: 'youtube-channel-description-generator',
  it: 'generatore-descrizioni-youtube',
  es: 'generador-descripciones-youtube',
  fr: 'generateur-description-youtube',
  de: 'youtube-kanalbeschreibungsgenerator',
  pt: 'gerador-descriçao-youtube',
}

const hashtagGenerator = {
  en: 'free-hashtag-generator',
  it: 'generatore-hashtag',
  es: 'generador-hashtags',
  fr: 'generateur-hashtags',
  de: 'hashtag-generator',
  pt: 'gerador-hashtags',
}

const instagramCaptionGenerator = {
  en: 'instagram-caption-generator',
  it: 'generatore-didascalie-instagram',
  es: 'generador-textos-instagram',
  fr: 'generateur-legendes-instagram',
  de: 'instagram-untertitel-generator',
  pt: 'gerador-legendas-instagram',
}

const conclusionGenerator = {
  en: 'conclusion-generator',
  it: 'generatore-conclusioni',
  es: 'generador-conclusiones',
  fr: 'generateur-conclusion',
  de: 'schlussfolgerungs-generator',
  pt: 'gerador-conclusao',
}

const paragraphRewriter = {
  en: 'paragraph-rewriter',
  it: 'riscrittore-paragrafi',
  es: 'reescritor-parrafos',
  fr: 'reecriveur-paragraphes',
  de: 'absatz-umformulierer',
  pt: 'reescritor-paragrafos',
}

const paraphrasingTool = {
  en: 'paraphrasing-tool',
  it: 'strumento-parafresi',
  es: 'herramienta-parafrasis',
  fr: 'outil-paraphrase',
  de: 'umformulierungs-werkzeug',
  pt: 'ferramenta-parafrase',
}

const rewordingTool = {
  en: 'rewording-tool',
  it: 'strumento-riformulazione',
  es: 'herramienta-reformulacion',
  fr: 'outil-reformulation',
  de: 'umschreibungs-tool',
  pt: 'ferramenta-reformulaçao',
}

const promptGenerator = {
  en: 'prompt-generator',
  it: 'generatore-di-prompt',
  es: 'generador-de-prompt',
  fr: 'generateur-de-prompts',
  de: 'prompt-generator',
  pt: 'gerador-de-prompts',
}

const workoutGenerator = {
  en: 'workout-generator',
  it: 'generatore-di-allenamento',
  es: 'generador-de-entrenamientos',
  fr: 'generateur-dentrainement',
  de: 'workout-generator',
  pt: 'gerador-de-treino',
}

const bookSummaryGenerator = {
  en: 'book-summary-generator',
  it: 'generatore-di-riassunto-libro',
  es: 'generador-de-resumenes-de-libros',
  fr: 'generateur-de-resume-de-livre',
  de: 'buchzusammenfassungs-generator',
  pt: 'gerador-de-resumo-de-livros',
}

const inspirationalQuoteGenerator = {
  en: 'inspirational-quote-generator',
  it: 'generatore-di-citazioni-iscpirative',
  es: 'generador-de-citas-inspiradoras',
  fr: 'generateur-de-citations-inspirantes',
  de: 'inspirationszitat-generator',
  pt: 'gerador-de-frases-inspiradoras',
}

const magicSpellNameGenerator = {
  en: 'magic-spell-name-generator',
  it: 'generatore-di-nomi-di-incantesimi-magici',
  es: 'generador-de-nombres-de-hechizos',
  fr: 'generateur-de-noms-de-sortileges',
  de: 'zauberspruch-namensgenerator',
  pt: 'gerador-de-nome-de-encantamento-magico',
}

const sentenceRewriter = {
  en: 'sentence-rewriter',
  it: 'rifasatore-di-frasi',
  es: 'reescritor-de-frases',
  fr: 'rephraseur-de-phrases',
  de: 'satz-umformulierer',
  pt: 'reescritor-de-frases',
}

const keywordGenerator = {
  en: 'keyword-generator',
  it: 'generatore-di-parole-chiave',
  es: 'generador-de-palabras-clave',
  fr: 'generateur-de-mots-cles',
  de: 'schluesselwort-generator',
  pt: 'gerador-de-palavras-chave',
}

const emojiTranslator = {
  en: 'emoji-translator',
  it: 'traduttore-di-emoji',
  es: 'traductor-de-emojis',
  fr: 'traducteur-d-emoji',
  de: 'emoji-uebersetzer',
  pt: 'tradutor-de-emojis',
}

const blogTitleGenerator = {
  en: 'blog-title-generator',
  it: 'generatore-di-titoli-di-blog',
  es: 'generador-de-titulos-de-blog',
  fr: 'generateur-de-titres-de-blog',
  de: 'blog-titel-generator',
  pt: 'gerador-de-titulo-de-blog',
}

const htmlCodeGenerator = {
  en: 'html-code-generator',
  it: 'generatore-di-codice-html',
  es: 'generador-de-codigo-html',
  fr: 'generateur-de-code-html',
  de: 'html-code-generator',
  pt: 'gerador-de-codigo-html',
}

const videoScriptGenerator = {
  en: 'video-script-generator',
  it: 'generatore-di-script-video',
  es: 'generador-de-guiones-de-video',
  fr: 'generateur-de-script-video',
  de: 'video-skript-generator',
  pt: 'gerador-de-roteiro-de-video',
}

const emailResponder = {
  en: 'email-responder',
  it: 'risponditore-di-email',
  es: 'respondedor-de-correo-electronico',
  fr: 'repondeur-email',
  de: 'email-antwort-generator',
  pt: 'resposta-de-email',
}

const tweetGenerator = {
  en: 'tweet-generator',
  it: 'generatore-di-tweet',
  es: 'generador-de-tweets',
  fr: 'generateur-de-tweets',
  de: 'tweet-generator',
  pt: 'gerador-de-tweets',
}

const resumeSkillsGenerator = {
  en: 'resume-skills-generator',
  it: 'generatore-di-competenze-per-il-curriculum',
  es: 'generador-de-habilidades-para-el-curriculum',
  fr: 'generateur-de-competences-pour-cv',
  de: 'lebenslauf-fahigkeiten-generator',
  pt: 'gerador-de-habilidades-para-curriculo',
}

const blogWritingTool = {
  en: 'blog-writing-tool',
  it: 'strumento-di-scrittura-per-blog',
  es: 'generador-de-contenido-para-blogs',
  fr: 'outil-redaction-blog',
  de: 'blog-schreibwerkzeug',
  pt: 'ferramenta-de-escrita-de-blog',
}
const productImageGenerator = {
  en: 'product-image-generator',
  it: 'generatore-immagini-prodotto-ai',
  es: 'generador-de-imagenes-de-producto',
  fr: 'generateur-image-produit',
  de: 'produktbild-generator',
  pt: 'gerador-de-imagens-de-produto',
}

const marketingMaterialGenerator = {
  en: 'marketing-material-generator',
  it: 'generatore-materiale-marketing-ai',
  es: 'generador-de-material-de-marketing',
  fr: 'generateur-materiel-marketing',
  de: 'marketingmaterial-generator',
  pt: 'gerador-de-material-de-marketing',
}

const conceptArtGenerator = {
  en: 'concept-art-generator',
  it: 'generatore-arte-concettuale-ai',
  es: 'generador-de-arte-conceptual',
  fr: 'generateur-art-conceptuel',
  de: 'konzeptkunst-generator',
  pt: 'gerador-de-arte-de-conceito',
}

const characterArtGenerator = {
  en: 'character-art-generator',
  it: 'generatore-di-personaggi-ai',
  es: 'generador-de-arte-de-personajes',
  fr: 'generateur-dart-de-personnage',
  de: 'charakterkunst-generator',
  pt: 'gerador-de-arte-de-personagem',
}

const illustrationGenerator = {
  en: 'illustration-generator',
  it: 'generatore-di-illustrazioni-ai',
  es: 'generador-de-ilustraciones',
  fr: 'generateur-dillustration-ai',
  de: 'illustrationsgenerator',
  pt: 'gerador-de-ilustracao',
}

const instagramPostGenerator = {
  en: 'instagram-post-generator',
  it: 'generatore-di-post-per-instagram',
  es: 'generador-de-publicaciones-de-instagram',
  fr: 'generateur-de-publications-instagram-ai',
  de: 'instagram-post-generator',
  pt: 'gerador-de-post-do-instagram',
}

const websiteMockupGenerator = {
  en: 'website-mockup-generator',
  it: 'generatore-di-mockup-per-siti-web',
  es: 'generador-de-mockups-de-sitios-web',
  fr: 'generateur-de-maquettes-de-sites-web-ai',
  de: 'website-mockup-generator',
  pt: 'gerador-de-mockup-de-site',
}

const avatarGenerator = {
  en: 'avatar-generator',
  it: 'generatore-di-avatar',
  es: 'generador-de-avatar',
  fr: 'generateur-davatar-ai',
  de: 'avatar-generator',
  pt: 'gerador-de-avatar',
}

const stockPhotoGenerator = {
  en: 'stock-photo-generator',
  it: 'generatore-di-foto-stock',
  es: 'generador-de-fotos-de-stock',
  fr: 'generateur-de-photos-libres-de-droits-ai',
  de: 'stock-photo-generator',
  pt: 'gerador-de-foto-de-estoque',
}

const model3dGenerator = {
  en: '3d-model-generator',
  it: 'generatore-di-modelli-3d',
  es: 'generador-de-modelos-3d',
  fr: 'generateur-de-modeles-3d-ai',
  de: '3d-modell-generator',
  pt: 'gerador-de-modelo-3d',
}

const tests = {
  'paragraph-typing-test': typingTool,
  'management-aptitude-test': managementTest,
  'communication-style-quiz': communicationQuiz,
  'conclusion-generator': conclusionGenerator,
  'paragraph-rewriter': paragraphRewriter,
  'paraphrasing-tool': paraphrasingTool,
  'rewording-tool': rewordingTool,
  'writing-hook-generator': hookGenerator,
  'youtube-channel-description-generator': youtubeChannelDescriptionGenerator,
  'free-hashtag-generator': hashtagGenerator,
  'instagram-caption-generator': instagramCaptionGenerator,
  'prompt-generator': promptGenerator,
  'workout-generator': workoutGenerator,
  'book-summary-generator': bookSummaryGenerator,
  'inspirational-quote-generator': inspirationalQuoteGenerator,
  'magic-spell-name-generator': magicSpellNameGenerator,
  'sentence-rewriter': sentenceRewriter,
  'keyword-generator': keywordGenerator,
  'emoji-translator': emojiTranslator,
  'blog-title-generator': blogTitleGenerator,
  'html-code-generator': htmlCodeGenerator,
  'video-script-generator': videoScriptGenerator,
  'email-responder': emailResponder,
  'tweet-generator': tweetGenerator,
  'resume-skills-generator': resumeSkillsGenerator,
  'blog-writing-tool': blogWritingTool,
  'product-image-generator': productImageGenerator,
  'marketing-material-generator': marketingMaterialGenerator,
  'concept-art-generator': conceptArtGenerator,
  'character-art-generator': characterArtGenerator,
  'illustration-generator': illustrationGenerator,
  'instagram-post-generator': instagramPostGenerator,
  'website-mockup-generator': websiteMockupGenerator,
  'avatar-generator': avatarGenerator,
  'stock-photo-generator': stockPhotoGenerator,
  '3d-model-generator': model3dGenerator,
}
function findTestByPage(page) {
  for (const [key, test] of Object.entries(tests)) {
    if (Object.values(test).includes(page)) {
      // Return the object that has the matching value
      return test
    }
  }
  // Return null if the page does not match any test
  return null
}

export const asPathMap = (asPath, locale) => {
  const decodedAsPath = decodeURIComponent(asPath)
  const [, category, page] = decodedAsPath.split('/')
  if (findTestByPage(page)) return `/${category}/${findTestByPage(page)[locale ?? 'en']}`
  else return asPath
}

export const Header = ({ type, clean, content, nolanguage, topbanner }) => {
  const [menu, setMenu] = useState(false)
  const [search, setSearch] = useState('')
  const [mobilemenu, setMobilemenu] = useState(false)
  const [login, setLogin] = useState(false)
  const [features, setFeatures] = useState(false)
  const [solutions, setSolutions] = useState(false)
  const [resources, setResources] = useState(false)
  const refLogin = useRef()
  const { asPath, locales, locale } = useRouter()

  useEffect(() => {
    setSearch(window?.location.search)
    sendPageview(window?.location.pathname)
  }, [])
  return (
    <header
      className={!clean ? `share${topbanner ? ' blog' : ''}` : ''}
      style={{ top: 0 }} //topbanner ? 40 : 0
    >
      <Link href={`/${search}`} className="logo" rel="noreferrer">
        <Image
          width={190}
          height={30}
          className="logo"
          src="/images/Logo.png"
          alt="Logo Company"
        />
      </Link>
      {!type ? (
        <>
          <div className="menu">
            {login && <Login refLogin={refLogin} setLogin={setLogin} />}
            <div
              id="menu1"
              className="dropdown"
              style={{ padding: '10px 0' }}
              onClick={() => setMenu(!menu)}
            >
              {content.product.title}{' '}
              <Image
                width={21}
                height={20}
                id="logoLong"
                src="/images/dropdown-caret.svg"
                className="dropdown"
                alt="Logo Company"
              />
              <div className="dropdown-content">
                {content.product.list?.map((product, index) => (
                  <Link key={index} href={product.link}>
                    <div
                      onClick={() =>
                        sendDataLayer(`${product.title} button top navigation`, product.title)
                      }
                    >
                      {product.title}
                    </div>
                  </Link>
                ))}
              </div>
            </div>
            <div id="menu1" className="dropdown" style={{ padding: '10px 0' }}>
              <Link href="/features">
                {content.features.title}{' '}
                <Image
                  width={21}
                  height={20}
                  id="logoLong"
                  src="/images/dropdown-caret.svg"
                  className="dropdown"
                  alt="Logo Company"
                />
              </Link>
              <div className="dropdown-content extensive features">
                {content.features.list.map((feature, index) => (
                  <Link key={index} href={feature.link}>
                    <Image width={50} height={50} src={feature.image} alt="Icon Tasks" />
                    <span className="title">{feature.title}</span>
                    <span className="description">{feature.description}</span>
                  </Link>
                ))}
              </div>
            </div>
            <div
              id="menu1"
              className="dropdown"
              style={{ padding: '10px 0' }}
              href="https://edworking.com/compare"
            >
              {content.compare.title}{' '}
              <Image
                width={21}
                height={20}
                id="logoLong"
                src="/images/dropdown-caret.svg"
                className="dropdown"
                alt="Logo Company"
              />
              <div className="dropdown-content" style={{ minWidth: 200 }}>
                <Link href="/compare/edworking-vs-asana">
                  <div
                    onClick={() =>
                      sendDataLayer('Asana button top navigation', 'Asana vs Edworking')
                    }
                  >
                    {content.compare.list[0]}
                  </div>
                </Link>
                <Link href="/compare/edworking-vs-clickup">
                  <div
                    onClick={() =>
                      sendDataLayer('Clickup button top navigation', 'Clickup vs Edworking')
                    }
                  >
                    {content.compare.list[1]}
                  </div>
                </Link>
                <Link href="/compare/edworking-vs-airtable">
                  <div
                    onClick={() =>
                      sendDataLayer('Airtable button top navigation', 'Airtable vs Edworking')
                    }
                  >
                    {content.compare.list[2]}
                  </div>
                </Link>
                <Link href="/compare">
                  <div
                    onClick={() =>
                      sendDataLayer(
                        'See all comparisions button top navigation',
                        'See all comparisions'
                      )
                    }
                  >
                    {content.compare.list[3]}
                  </div>
                </Link>
              </div>
            </div>
            <div id="menu1" className="dropdown" style={{ padding: '10px 0' }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {content.learn.title}{' '}
                <Image
                  width={21}
                  height={20}
                  id="logoLong"
                  src="/images/dropdown-caret.svg"
                  className="dropdown"
                  alt="Logo Company"
                />
              </div>
              <div className="dropdown-content extensive">
                {content.learn.list
                  .filter((e) => e.link)
                  .map((element, index) => (
                    <Link key={index} href={element.link}>
                      <Image width={50} height={50} src={element.image} alt="Icon" />
                      <span className="title">{element.title}</span>
                      <span className="description">{element.description}</span>
                    </Link>
                  ))}
              </div>
            </div>
            <Link className="pricing" href="/pricing">
              {content.pricing.title}
            </Link>
            {!nolanguage && (
              <div
                id="menu2"
                className="dropdown"
                style={{ padding: '10px 0', display: 'flex' }}
              >
                <LanguageSelector />
                <div className="dropdown-content" style={{ minWidth: 100, left: -35 }}>
                  {locales.map((locale, index) => {
                    return (
                      <Link href={asPathMap(asPath, locale)} key={index} locale={locale}>
                        <div
                          onClick={() =>
                            sendDataLayer(`Language selector ${locale} top navigation`, locale)
                          }
                        >
                          {prettify(locale)}
                        </div>
                      </Link>
                    )
                  })}
                </div>
              </div>
            )}
            <div
              id="signinbutton"
              className="signin"
              ref={refLogin}
              onClick={() => {
                sendDataLayer('Login button top navigation', 'Login')
                setLogin(true)
              }}
            >
              {content.login.title}
            </div>
            <div
              id="signinbutton"
              className="signin mobile"
              onClick={() => {
                sendDataLayer('Signin button mobile top navigation', 'Login')
                window.location = 'https://edworking.com/redirect/'
              }}
            >
              {content.login.title}
            </div>
            <Link
              href={`https://app.edworking.com/signup/user${search}`}
              rel="noopener"
              style={{ marginRight: 0, marginLeft: 0 }}
              className="signup"
            >
              <div onClick={() => sendDataLayer('Signup button top navigation', 'Signup')}>
                {content.signup.title}
              </div>
            </Link>
            <div id="menuBurger" onClick={() => setMobilemenu(!mobilemenu)}>
              <Image
                width={17}
                height={13}
                id="burger"
                src={mobilemenu ? '/images/Close.png' : '/images/Burger.png'}
                alt="Burger"
              />
            </div>
            <div
              className="menumobile"
              id="menumobile"
              style={{ display: mobilemenu ? 'flex' : 'none' }}
            >
              <div className="category">
                <div
                  className="element"
                  onClick={() => {
                    setSolutions(!solutions)
                    setFeatures(false)
                    setResources(false)
                  }}
                >
                  <span>{content.product.title}</span>
                  <Image
                    width={7}
                    height={11}
                    className="arrow"
                    style={{ transform: `rotate(${solutions ? 90 : 0}deg)` }}
                    src="/images/RightArrowBlack.svg"
                    alt="Arrow"
                  />
                </div>
                <div className={`subcategory${solutions ? ' show' : ' hidden'}`}>
                  {content.product.list.map((product, index) => (
                    <Link href={product.link} key={index} className="element">
                      <span>{product.title}</span>
                      <Image
                        width={7}
                        height={11}
                        className="arrow"
                        src="/images/RightArrowBlack.svg"
                        alt="Arrow"
                      />
                    </Link>
                  ))}
                </div>
              </div>
              <div className="category">
                <div
                  className="element"
                  onClick={() => {
                    setSolutions(false)
                    setFeatures(!features)
                    setResources(false)
                  }}
                >
                  <span>{content.features.title}</span>
                  <Image
                    width={7}
                    height={11}
                    className="arrow"
                    style={{ transform: `rotate(${features ? 90 : 0}deg)` }}
                    src="/images/RightArrowBlack.svg"
                    alt="Arrow"
                  />
                </div>
                <div className={`subcategory${features ? ' show' : ' hidden'}`}>
                  {content.features.list.map((feature, index) => (
                    <Link href={feature.link} key={index} className="element">
                      <span>{feature.title}</span>
                      <Image
                        width={7}
                        height={11}
                        className="arrow"
                        src="/images/RightArrowBlack.svg"
                        alt="Arrow"
                      />
                    </Link>
                  ))}
                </div>
              </div>
              <Link href="/compare" className="element">
                <span>{content.compare.title}</span>
                <Image
                  width={7}
                  height={11}
                  className="arrow"
                  src="/images/RightArrowBlack.svg"
                  alt="Arrow"
                />
              </Link>
              <div className="category">
                <div
                  className="element"
                  onClick={() => {
                    setSolutions(false)
                    setFeatures(false)
                    setResources(!resources)
                  }}
                >
                  <span>{content.learn.title}</span>
                  <Image
                    width={7}
                    height={11}
                    className="arrow"
                    style={{ transform: `rotate(${resources ? 90 : 0}deg)` }}
                    src="/images/RightArrowBlack.svg"
                    alt="Arrow"
                  />
                </div>
                <div className={`subcategory${resources ? ' show' : ' hidden'}`}>
                  {content.learn.list.map((resource, index) => (
                    <Link href={resource.link} key={index} className="element">
                      <span>{resource.title}</span>
                      <Image
                        width={7}
                        height={11}
                        className="arrow"
                        src="/images/RightArrowBlack.svg"
                        alt="Arrow"
                      />
                    </Link>
                  ))}
                </div>
              </div>
              <Link href="/pricing" className="element">
                <span>{content.pricing.title}</span>
                <Image
                  width={7}
                  height={11}
                  className="arrow"
                  src="/images/RightArrowBlack.svg"
                  alt="Arrow"
                />
              </Link>
              <Link
                className="whiteButton"
                style={{ width: 'fit-content' }}
                href="/redirect"
                locale="en"
              >
                <Image
                  width={20}
                  height={15}
                  className="logoSmall"
                  src="/images/Logo.svg"
                  alt="Arrow"
                />
                <span>{content.mobileapp.title}</span>
              </Link>
            </div>
          </div>
        </>
      ) : type === 'plan' ? (
        <div className="menu">
          <a href="mailto:ivan.arozamena@edworking.com" rel="noopener">
            <div
              style={{ marginRight: 0, marginLeft: 0, width: 200 }}
              className="signup blueButton"
            >
              {content.founder.title}
            </div>
          </a>
        </div>
      ) : (
        type === 'workspace' && (
          <div className="menu">
            <a
              href={`https://app.edworking.com/signup/user${search}`}
              style={{ color: 'white' }}
              className="blueButton"
              rel="noopener"
            >
              {content?.signup ? content.signup.title : 'Get Started Now'}
            </a>
          </div>
        )
      )}
    </header>
  )
}
const LanguageSelector = () => {
  const [hover, setHover] = useState(false)
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 19 20"
      version="1.1"
      className={'language'}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <g
        id="Laguage-selector-Tests"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Contact-sales_update-mock" transform="translate(-1082.000000, -552.000000)">
          <g id="Nav-Copy-3" transform="translate(60.000000, 534.000000)">
            <polygon id="Nav-bg" fill="none" points="0 0 1440 0 1440 56 0 56"></polygon>
            <g
              id="globe"
              transform="translate(1022.000000, 18.500000)"
              fill={!hover ? '#646F79' : '#16DAf4'}
              fillRule="nonzero"
            >
              <path
                d="M9.5,0 C4.275,0 0,4.275 0,9.5 C0,14.725 4.275,19 9.5,19 C14.725,19 19,14.725 19,9.5 C19,4.275 14.725,0 9.5,0 Z M16.98125,5.9375 L13.359375,5.9375 C13.0625,4.096875 12.528125,2.553125 11.815625,1.54375 C14.13125,2.196875 15.971875,3.8 16.98125,5.9375 Z M12.46875,9.5 C12.46875,10.33125 12.409375,11.103125 12.35,11.875 L6.65,11.875 C6.590625,11.103125 6.53125,10.33125 6.53125,9.5 C6.53125,8.66875 6.590625,7.896875 6.65,7.125 L12.35,7.125 C12.409375,7.896875 12.46875,8.66875 12.46875,9.5 Z M9.5,17.8125 C8.490625,17.8125 7.3625,15.971875 6.828125,13.0625 L12.171875,13.0625 C11.6375,15.971875 10.509375,17.8125 9.5,17.8125 Z M6.828125,5.9375 C7.3625,3.028125 8.490625,1.1875 9.5,1.1875 C10.509375,1.1875 11.6375,3.028125 12.171875,5.9375 L6.828125,5.9375 Z M7.184375,1.54375 C6.471875,2.553125 5.9375,4.096875 5.640625,5.9375 L2.01875,5.9375 C3.028125,3.8 4.86875,2.196875 7.184375,1.54375 Z M1.54375,7.125 L5.4625,7.125 C5.403125,7.896875 5.34375,8.66875 5.34375,9.5 C5.34375,10.33125 5.403125,11.103125 5.4625,11.875 L1.54375,11.875 C1.30625,11.103125 1.1875,10.33125 1.1875,9.5 C1.1875,8.66875 1.30625,7.896875 1.54375,7.125 Z M2.01875,13.0625 L5.640625,13.0625 C5.9375,14.903125 6.471875,16.446875 7.184375,17.45625 C4.86875,16.803125 3.028125,15.2 2.01875,13.0625 Z M11.815625,17.45625 C12.528125,16.3875 13.0625,14.903125 13.359375,13.0625 L16.98125,13.0625 C15.971875,15.2 14.13125,16.803125 11.815625,17.45625 Z M17.45625,11.875 L13.5375,11.875 C13.596875,11.103125 13.65625,10.33125 13.65625,9.5 C13.65625,8.66875 13.596875,7.896875 13.5375,7.125 L17.45625,7.125 C17.69375,7.896875 17.8125,8.66875 17.8125,9.5 C17.8125,10.33125 17.69375,11.103125 17.45625,11.875 Z"
                id="Shape"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
export const Footer = () => {
  const [telegram, setTelegram] = useState(false)
  const [instagram, setInstagram] = useState(false)
  const [linkedin, setLinkdin] = useState(false)
  return (
    <footer>
      <Image
        width={37}
        height={24}
        src="/images/Logo.svg"
        className="logo"
        alt="Company Logo"
      />
      <div className="contact">
        <Link href="/feedback/feature-requests/">Feedback</Link>
      </div>
      <div className="contact">
        <Link href="/privacy">Privacy</Link>
      </div>
      <div className="contact">
        <Link href="/faq">FAQs</Link>
      </div>
      <div className="contact">
        <Link href="/terms">Terms</Link>
      </div>
      <div className="social">
        <span>Find us on:</span>
        <a href="https://t.me/edworkingapp/" target="_blank" rel="noreferrer">
          <Image
            width={46}
            height={46}
            onMouseOver={() => setTelegram(true)}
            onMouseLeave={() => setTelegram(false)}
            className={telegram ? 'dark' : 'light'}
            src={telegram ? '/images/tw.png' : '/images/tb.png'}
            alt="Telegram"
          />
        </a>
        <a href="https://www.instagram.com/edworkingapp/" target="_blank" rel="noreferrer">
          <Image
            width={46}
            height={46}
            onMouseOver={() => setInstagram(true)}
            onMouseLeave={() => setInstagram(false)}
            className={instagram ? 'dark' : 'light'}
            src={instagram ? '/images/igw.png' : '/images/igb.png'}
            alt="Instagram"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/edworkingremote/"
          target="_blank"
          rel="noreferrer"
        >
          <Image
            width={46}
            height={46}
            onMouseOver={() => setLinkdin(true)}
            onMouseLeave={() => setLinkdin(false)}
            className={linkedin ? 'dark' : 'light'}
            src={linkedin ? '/images/inw.png' : '/images/inb.png'}
            alt="Linkedin"
          />
        </a>
      </div>
    </footer>
  )
}
export const Category = ({ empty, category, rawCategory, pagination, articles }) => {
  if (articles.length === 0) {
    return (
      <section className="similararticles">
        <div className={`sectiontop ${category}`}>
          <span className="title">{category}</span>
        </div>
        <div className="single empty">
          <Image
            width={600}
            height={300}
            className="cover"
            src={'/images/EmptyDescription.png'}
            alt="Cover"
          />
          <h2 className="title">{empty.title}</h2>
          <span className="resume">{empty.section}</span>
        </div>
      </section>
    )
  }
  const isGuides = category === 'guides' && pagination
  return (
    <section
      className="similararticles"
      style={{ marginTop: !isGuides && pagination ? 150 : 0 }}
    >
      <div className={`sectiontop ${category}`}>
        <div style={{ display: 'flex' }}>
          {!isGuides && pagination && (
            <>
              <Link href={`/blog`} style={{ padding: 0 }}>
                <span className="title">Blog</span>
              </Link>
              <span className="title bread">{'>'}</span>
            </>
          )}
          {isGuides ? (
            <span className="title">
              {category === 'remotework' ? 'Remote Work' : category}
            </span>
          ) : (
            <Link href={rawCategory ? `/blog/${rawCategory}` : '/blog'} style={{ padding: 0 }}>
              <span className="title">
                {category === 'remotework' ? 'Remote Work' : category}
              </span>
            </Link>
          )}
        </div>
        {!pagination && rawCategory && (
          <Link
            href={category === 'guides' ? `/guides` : `/blog/${rawCategory}`}
            className="more"
          >
            See All {capitalized(category)} Articles
          </Link>
        )}
      </div>
      <div className="articles">
        {articles
          ?.filter((a) => a.cover)
          .map((article, index) => {
            const author1 = article?.author?.firstname + ' ' + article?.author?.lastname,
              created1 = timeDiff(article?.createdAt)
            //length1 = Math.ceil(article?.article.split(" ").length/(200))
            return (
              <Link
                key={index}
                className="single"
                href={
                  category === 'guides'
                    ? `/${article.category}/${article.title}`
                    : `/blog/${article.category}/${article.title}`
                }
              >
                <Image
                  width={250}
                  height={170}
                  className="cover"
                  src={article.cover}
                  alt="Cover"
                  style={{ backgroundColor: article?.color === '#b491c5' ? '#b491c5' : null }}
                />
                <h2 className="title">{article.rawTitle}</h2>
                <span className="resume">{article.resume}</span>
                <div className="subtitle">
                  <span className="author">
                    BY <span>{author1}</span>
                  </span>
                  <span className="created">{created1}</span>
                </div>
              </Link>
            )
          })}
        {pagination && <Pagination />}
      </div>
    </section>
  )
}
const capitalized = (word) => word.charAt(0).toUpperCase() + word.slice(1)

export const VideoPodcasts = ({ empty, category, pagination, articles, rawCategory }) => {
  if (articles.length === 0) {
    return (
      <section className="similararticles">
        <div className={`sectiontop ${category}`}>
          <span className="title">{category}</span>
        </div>
        <div className="single empty">
          <Image
            width={200}
            height={200}
            className="cover"
            src={'/images/EmptyDescription.png'}
            alt="Cover"
          />
          <h2 className="title">{empty.title}</h2>
          <span className="resume">{empty.section}</span>
        </div>
      </section>
    )
  }
  return (
    <section className="similararticles">
      <div className={`sectiontop ${category}`}>
        <Link href={`/blog/${rawCategory}`} style={{ padding: 0 }}>
          <span className="title">{category === 'remotework' ? 'Remote Work' : category}</span>
        </Link>
        <Link href={`/blog/${rawCategory}`} className="more">
          See All {capitalized(category)} Articles
        </Link>
      </div>
      <div className="articles">
        {articles.map((article, index) => {
          //const author1 = article?.author?.firstname + ' ' + article?.author?.lastname,
          //      created1 = timeDiff(article?.createdAt) style={{marginRight: width < 1000 & ((i + 1 === index) || (i + 3 === index)) ? 0 : i + 3 === index && 0}}
          return (
            <Link key={index} href={`/blog/podcast/${article.title}`} className="single">
              <div className="cover text">
                <span>{article.rawTitle}</span>
                <Image
                  width={1000}
                  height={500}
                  className="cover"
                  src={article.cover}
                  alt="Cover"
                />
              </div>
              <h2 className="title">{article.rawTitle}</h2>
              <span className="resume">{article.resume}</span>
            </Link>
          )
        })}
        {pagination && <Pagination />}
      </div>
    </section>
  )
}

export const LastArticles = ({ articles }) => {
  const [article1, article2, article3] = articles,
    author1 = article1?.author?.firstname + ' ' + article1?.author?.lastname,
    created1 = timeDiff(article1?.createdAt),
    //length1 = Math.ceil(article1?.article.split(" ").length/(200)),
    author2 = article2?.author?.firstname + ' ' + article2?.author?.lastname,
    created2 = timeDiff(article2?.createdAt),
    //length2 = Math.ceil(article2?.article.split(" ").length/(200)),
    author3 = article3?.author?.firstname + ' ' + article3?.author?.lastname,
    created3 = timeDiff(article3?.createdAt)
  //length3 = Math.ceil(article3?.article.split(" ").length/(200))

  return (
    <section className="lastarticles">
      <Link
        href={`/blog/${article1.category}/${article1.title}`}
        className="leftsection"
        style={{ background: article1.color ? article1.color : 'white' }}
      >
        <Image width={1000} height={500} src={`${article1.cover}`} alt="" />
        <div className="rectangle">
          <h2 className="title">{article1.rawTitle}</h2>
          <div className="subtitle">
            <span className="author">
              BY <span>{author1}</span>
            </span>
            <span className="created">{created1}</span>
            {/*<span className="length">{length1} MINS READ</span>*/}
          </div>
        </div>
      </Link>
      <div className="rightsection">
        <Link className="single" href={`/blog/${article2.category}/${article2.title}`}>
          <h2 className="title">{article2.rawTitle}</h2>
          <div className="resume">{article2.resume}</div>
          <div className="subtitle">
            <span className="author">
              BY <span>{author2}</span>
            </span>
            <span className="created">{created2}</span>
            {/*<span className="length">{length2} MINS READ</span>*/}
          </div>
        </Link>
        <Link className="single" href={`/blog/${article3.category}/${article3.title}`}>
          <h2 className="title">{article3.rawTitle}</h2>
          <div className="resume">{article3.resume}</div>
          <div className="subtitle">
            <span className="author">
              BY <span>{author3}</span>
            </span>
            <span className="created">{created3}</span>
            {/*<span className="length">{length3} MINS READ</span>*/}
          </div>
        </Link>
      </div>
    </section>
  )
}
export const SimilarArticles = ({ similar, title }) => {
  const router = useRouter()
  return (
    <section className="similararticles nobanner">
      <div className="sectiontop">
        <h2 className="title">{title ? title : 'Similar Articles'}</h2>
        <Link href={'/blog'} className="more">
          See All Articles
        </Link>
      </div>
      <div className="articles">
        {similar
          ?.filter((a) => a.cover)
          .map((article, index) => {
            const author = article?.author?.firstname + ' ' + article?.author?.lastname,
              created = timeDiff(article?.createdAt) //,
            //length = Math.ceil(article?.article.split(" ").length/(200))
            return (
              <Link
                key={index}
                className="single"
                href={`/blog/${article.category}/${article.title}`}
              >
                <Image
                  width={1000}
                  height={500}
                  className="cover"
                  src={article.cover}
                  alt="Cover"
                />
                <span className="title">{article.rawTitle}</span>
                <span className="resume">{article.resume}</span>
                <div className="subtitle">
                  <span className="author">
                    BY <span>{author}</span>
                  </span>
                  <span className="created">{created}</span>
                </div>
              </Link>
            )
          })}
      </div>
      <Script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js-eu1.hs-scripts.com/25992597.js"
      ></Script>
    </section>
  )
}

const appsList = [
  { id: 1, name: 'Slack', icon: '/images/calculator/icon_0_0.png', price: 9.0 },
  { id: 2, name: 'Teams', icon: '/images/calculator/icon_1_5.png', price: 8.0 },
  { id: 3, name: 'Google Drive', icon: '/images/calculator/icon_0_1.png', price: 13.0 },
  { id: 4, name: 'Salesforce', icon: '/images/calculator/icon_3_3.png', price: 25.0 },
  { id: 5, name: 'HubSpot', icon: '/images/calculator/icon_2_2.png', price: 20.0 },
  { id: 6, name: 'ChatGPT', icon: '/images/calculator/icon_2_1.png', price: 20.0 },
  { id: 7, name: 'Jira', icon: '/images/calculator/icon_2_0.png', price: 12.0 },
  { id: 8, name: 'Loom', icon: '/images/calculator/icon_1_1.png', price: 12.0 },
  { id: 9, name: 'Notion', icon: '/images/calculator/icon_3_5.png', price: 12.0 },
  { id: 10, name: 'Monday', icon: '/images/calculator/icon_1_4.png', price: 12.0 },
  { id: 11, name: 'Trello', icon: '/images/calculator/icon_4_0.png', price: 11.0 },
  { id: 12, name: 'Asana', icon: '/images/calculator/icon_0_3.png', price: 15.0 },
  { id: 13, name: 'Zoom', icon: '/images/calculator/icon_3_1_2.png', price: 18.0 },
  { id: 14, name: 'Dropbox', icon: '/images/calculator/icon_3_1.png', price: 18.0 },
  { id: 15, name: 'Linear', icon: '/images/calculator/icon_2_4.png', price: 11.0 },
  { id: 16, name: 'ProductBoard', icon: '/images/calculator/icon_3_2.png', price: 20.0 },
  { id: 17, name: 'Discord', icon: '/images/calculator/icon_1_3.png', price: 10.0 },
  { id: 18, name: 'Smartsheet', icon: '/images/calculator/icon_3_4.png', price: 16.0 },
  { id: 19, name: 'Google Meets', icon: '/images/calculator/icon_2_3_2.png', price: 13.0 },
  { id: 20, name: 'Coda', icon: '/images/calculator/icon_0_2.png', price: 15.0 },
  { id: 21, name: 'Sharepoint', icon: '/images/calculator/icon_2_5.png', price: 14.0 },
  { id: 22, name: 'Confluence', icon: '/images/calculator/icon_0_5.png', price: 9.0 },
  { id: 23, name: 'Taskade', icon: '/images/calculator/icon_3_0_2.png', price: 10.0 },
  { id: 24, name: 'Airtable', icon: '/images/calculator/icon_1_2.png', price: 15.0 },
  // Add other apps here with prices
]

const sliderValues = [
  1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
  1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000,
]

const mapRangeValue = (value) => sliderValues[value]
const calculateTooltipPosition = (value) => (value / (sliderValues.length - 1)) * 100

export const CalculatorCost = ({ content }) => {
  const defaultSelectedApps = [1, 6, 8, 9, 11, 13, 14] // IDs of Slack, ChatGPT, Notion, Asana, Zoom, and Dropbox
  const defaultEmployees = 50 // Default to 50 users

  const [selectedApps, setSelectedApps] = useState(defaultSelectedApps)
  const [employees, setEmployees] = useState(defaultEmployees)
  const [hoveredApp, setHoveredApp] = useState(null)
  const [sliderValue, setSliderValue] = useState(sliderValues.indexOf(defaultEmployees))
  const [displayedCost, setDisplayedCost] = useState('$0.00/year')
  const animationRefs = useRef([])
  const [alertMessage, setAlertMessage] = useState('')

  const toggleAppSelection = (appId) => {
    setSelectedApps((prevSelectedApps) => {
      const newSelection = prevSelectedApps.includes(appId)
        ? prevSelectedApps.filter((id) => id !== appId)
        : [...prevSelectedApps, appId]

      if (newSelection.length < 1) {
        setAlertMessage('Please select at least 1 app for the calculator')
        return prevSelectedApps
      } else {
        setAlertMessage('')
        return newSelection
      }
    })
  }

  useEffect(() => {
    const newTotalCost = calculateTotalCost()
    const formattedCost = `$${newTotalCost.toLocaleString()}/year`
    setDisplayedCost(formattedCost)

    const costString = formattedCost.split('')
    animationRefs.current.forEach((ref, index) => {
      if (ref) {
        ref.classList.remove('slot-animation', 'slot-animation-slow', 'slot-animation-fast')
        void ref.offsetWidth // Trigger reflow
        ref.textContent = '' // Clear content for animation effect
      }
    })

    setTimeout(() => {
      animationRefs.current.forEach((ref, index) => {
        if (ref) {
          const animationClass = [
            'slot-animation-fast',
            'slot-animation',
            'slot-animation-slow',
          ][index % 3]
          ref.classList.add(animationClass)
          setTimeout(() => {
            ref.textContent = costString[index] || '' // Update text content after animation
          }, 300) // Sync with the fastest animation duration
        }
      })
    }, 50)
  }, [selectedApps, employees])

  const calculateTotalCost = () => {
    return selectedApps.reduce((total, appId) => {
      const app = appsList.find((app) => app.id === appId)
      return total + (app ? app.price * employees * 12 : 0)
    }, 0)
  }

  const handleEmployeesChange = (e) => {
    const value = parseInt(e.target.value, 10)
    setSliderValue(value)
    setEmployees(mapRangeValue(value))
  }

  const sliderPercentage = calculateTooltipPosition(sliderValue)

  return (
    <div className="calculator-cost">
      <h1>{content.title}</h1>
      <p>{content.description}</p>
      <div className="content">
        <div className="apps-selection">
          <h2>{content.yourapp}</h2>
          {alertMessage && <div className="alert">{alertMessage}</div>}
          <div className="apps-list">
            {appsList.map((app) => (
              <div
                key={app.id}
                className={`app-icon ${selectedApps.includes(app.id) ? 'selected' : ''}`}
                onClick={() => toggleAppSelection(app.id)}
                onMouseEnter={() => setHoveredApp(app)}
                onMouseLeave={() => setHoveredApp(null)}
              >
                <Image width={40} height={40} src={app.icon} alt={app.name} />
                {hoveredApp && hoveredApp.id === app.id && (
                  <div className="app-tooltip">
                    {app.name} @ ${app.price} {content.peruser}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="employees-count">
            <label htmlFor="employees">{content.question}</label>
            <div className="slider-container">
              <input
                type="range"
                id="employees"
                name="employees"
                min="0"
                max={sliderValues.length - 1}
                step="1"
                value={sliderValue}
                onChange={handleEmployeesChange}
                style={{
                  background: `linear-gradient(to right, #0fc8ef ${sliderPercentage}%, #ddd 0)`,
                }}
              />
              <div className="tooltip" style={{ left: `calc(${sliderPercentage}% - -13px)` }}>
                {employees}
              </div>
            </div>
            <div className="employees-value">
              <span style={{ left: '0%' }}>1</span>
              <span style={{ left: '37%' }}>100</span>
              <span style={{ left: '72%' }}>1000</span>
              <span style={{ left: '97%' }}>5000+</span>
            </div>
          </div>
        </div>
        <div className="cost-calculation">
          <h2>{content.total}</h2>
          <div className="cost-details">
            <h3>
              {content.replace} {selectedApps.length}{' '}
              {selectedApps.length === 1 ? 'app' : 'apps'}
            </h3>
            <p>{content.estimation}</p>
            <h3 className="total-cost">
              {content.save} {displayedCost}
            </h3>
            <p>
              {content.saving1}
              {employees}
              {content.saving2}
              {displayedCost}
              {content.saving3}
            </p>
            <Link
              href="https://app.edworking.com/signup/user"
              className="blueButton"
              style={{ width: 'fit-content' }}
            >
              {content.startsaving}
            </Link>
            <p className="quote-link">
              <Link href="/contact">{content.getquote}</Link>
              {content.getquota2}
            </p>
            <Link href="/reviews" className="testimonial-block">
              <div className="testimonial">
                <div className="rating">★★★★★</div>
                <p className="testimonial-text">"{content.review}"</p>
                <span className="author">Sandra S</span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
